import "./termsAndConditions.scss";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions container">
      <div className="row">
        <div className="col-12 my-5 px-4">
          <h1>Terms and Conditions</h1>
          <h2>Welcome to Ibad Mobile!</h2>
          <p>
            These terms and conditions ("Terms", "Terms and Conditions") outline
            the rules and regulations for your use of the Ibad Mobile website
            (the "Service") located at{" "}
            <a
              href="https://ibadmobile.com/"
              target="_blank"
              className="underline"
            >
              https://ibadmobile.com/
            </a>
            .
          </p>
          <p className="fw-bold ">
            By accessing or using the Service, you agree to be bound by these
            Terms and Conditions and our Privacy Policy.
          </p>
          <h3>1. Use of the Service</h3>
          <ul>
            <li>
              <p>
                You must be at least 13 years old to access or use the Service.
              </p>
            </li>
            <li>
              <p>
                You agree to use the Service only for lawful purposes and in
                accordance with these Terms.
              </p>
            </li>
            <li style={{ marginBottom: "4px" }}>
              <p>Prohibited activities include:</p>
            </li>
          </ul>
          <ul style={{ marginLeft: "60px" }} className="style-type-circle">
            <li>
              <p>Infringing on intellectual property rights</p>
            </li>
            <li>
              <p>Engaging in fraudulent activity</p>
            </li>
            <li>
              <p>Transmitting harmful or offensive content</p>
            </li>
            <li>
              <p>Disrupting the Service or its servers</p>
            </li>
            <li>
              <p>Impersonating any person or entity</p>
            </li>
          </ul>
          <h3>2. Intellectual Property Rights</h3>
          <ul>
            <li>
              <p>
                Ibad Mobile and/or its licensors own all intellectual property
                rights for the content on the Service.
              </p>
            </li>
            <li>
              <p>
                You may access the content for your personal use only, subject
                to the restrictions set forth in these Terms.
              </p>
            </li>
            <li style={{ marginBottom: "4px" }}>
              <p>You are not permitted to:</p>
            </li>
          </ul>
          <ul style={{ marginLeft: "60px" }} className="style-type-circle">
            <li>
              <p>
                Reproduce, distribute, modify, or create derivative works of the
                content
              </p>
            </li>
            <li>
              <p>Sell, rent, or sub-license the content</p>
            </li>
          </ul>
          <h3>3. User Comments</h3>
          <ul>
            <li>
              <p>
                Ibad Mobile offers areas for users to post comments and
                interact.
              </p>
            </li>

            <li>
              <p>
                Ibad Mobile does not filter, edit, publish, or review comments
                before they appear.
              </p>
            </li>

            <li>
              <p>
                Comments reflect the views and opinions of the users who post
                them, not Ibad Mobile.
              </p>
            </li>

            <li>
              <p>
                You warrant and represent that you have the right to post
                comments and that your comments do not infringe on any
                third-party rights.
              </p>
            </li>

            <li>
              <p>
                Ibad Mobile reserves the right to remove any comments deemed
                inappropriate or in violation of these Terms.
              </p>
            </li>
          </ul>
          <h3>4. Linking to Ibad Mobile</h3>
          <p>
            Organizations may link to Ibad Mobile's website with prior written
            permission. Approval will be granted based on criteria outlined in a
            separate document available upon request. Linking organizations must
            not imply false sponsorship or endorsement and the link must fit
            within the context of the linking party's site. Ibad Mobile reserves
            the right to revoke linking permission at any time.
          </p>
          {/* <ul>
            <li>
              <p>
                Organizations may link to Ibad Mobile's website with prior
                written permission.
              </p>
            </li>

            <li>
              <p>
                Approval will be granted based on criteria outlined in the full
                Terms and Conditions document (available upon request).
              </p>
            </li>

            <li>
              <p>
                Linking organizations must not imply false sponsorship or
                endorsement and the link must fit within the context of the
                linking party's site.
              </p>
            </li>

            <li>
              <p>
                Ibad Mobile reserves the right to revoke linking permission at
                any time.
              </p>
            </li>
          </ul> */}
          <h3>5. Advertisements</h3>
          <p>
            Ibad Mobile uses Google AdSense to display advertisements on our
            website. These ads are chosen and delivered by Google based on your
            browsing activity and interests. We do not endorse or recommend any
            products or services advertised on our website. You should always
            perform your own research before clicking on any advertisements.
            Ibad Mobile is not responsible for the content of the advertisements
            displayed. For more information about advertising practices, please
            refer to Google's Advertising Policies:{" "}
            <a
              href="https://support.google.com/adspolicy/answer/6008942?hl=en"
              target="_blank"
              className="underline"
            >
              https://support.google.com/adspolicy/answer/6008942?hl=en
            </a>
            .
          </p>

          <h3>6. Disclaimer</h3>
          <p>
            Ibad Mobile strives to provide accurate information but does not
            guarantee its completeness or suitability for any specific purpose.
            The website and its content are provided "as is" without warranty of
            any kind. Ibad Mobile excludes all liability for any loss or damage
            arising from the use of the website or its content.
          </p>
          {/* <ul>
            <li>
              <p>
                Ibad Mobile strives to provide accurate information but does not
                guarantee its completeness or suitability for any specific
                purpose.
              </p>
            </li>

            <li>
              <p>
                The website and its content are provided "as is" without
                warranty of any kind.
              </p>
            </li>

            <li>
              <p>
                Ibad Mobile excludes all liability for any loss or damage
                arising from the use of the website or its content.
              </p>
            </li>
          </ul> */}
          <h3>7. Governing Law</h3>
          <p>
            These Terms and Conditions shall be governed and construed in
            accordance with applicable laws.
          </p>
          <h3>8. Contact Us</h3>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at:
          </p>
          <a
            href="mailto:contact@ibadmobile.com"
            className="underline"
          >
            contact@ibadmobile.com
          </a>
          {/* <h3>Disclaimer</h3>
          <p>
            The information on this website is for general information purposes
            only and does not constitute professional advice. Ibad Mobile does
            not warrant the completeness, accuracy, or reliability of this
            information.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
