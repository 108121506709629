import React from "react";
import successImage from "../../../../assets/success_post_ad.png";

interface SuccessPopUpProps {
  onClose: () => void; // Function to close the popup
}

const SuccessPopUp: React.FC<SuccessPopUpProps> = ({ onClose }) => {
  return (
    <div className="popup-overlay-success">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2 className="text-info">Successfully Posted</h2>
        <img
          src={successImage} // Use the success image here
          alt="Success"
          style={{ width: "120px", height: "120px" }}
        />
        <p className="first-text">Your Ad has been posted successfully!</p>
        <p>Your Ad will soon be reachable to millions of buyers</p>
      </div>
    </div>
  );
};

export default SuccessPopUp;
