import React, { useEffect, useState } from "react";
import apple from "../../assets/apple.png";
import samsung from "../../assets/samsung.png";
import oppo from "../../assets/oppo.png";
import asus from "../../assets/asus.png";
import pixel from "../../assets/pixel.png";
import vivo from "../../assets/vivo.png";
import xaiomi from "../../assets/xiaomi.png";
import itel from "../../assets/itel.png";
import tecno from "../../assets/tecno.png";
import sony from "../../assets/sony.png";
import lenovo from "../../assets/lenovo.png";
import huawei from "../../assets/huawei.png";
import motorola from "../../assets/motorola.png";
import nothing from "../../assets/nothing.png";
import oneplus from "../../assets/oneplus.png";
import infinix from "../../assets/infinix.png";
import nokia from "../../assets/nokia.png";
import { MobileData } from "../FeaturedMobile/interface2";
import "./branding.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Link, useNavigate } from "react-router-dom";
import slugs from "../../config/slugConfig";

const Branding: React.FC = () => {
  // const [documents, setDocuments] = useState<MobileData[]>([]);
  const mobileData: MobileData[] = useSelector(
    (state: RootState) => state.mobiles.mobiles
  );

  const navigate = useNavigate();

  const [showAllLogos, setShowAllLogos] = useState(false);
  const logos: { text: string; image: string }[] = [
    { text: "Apple", image: apple },
    { text: "Samsung", image: samsung },
    { text: "Oppo", image: oppo },
    { text: "Oneplus", image: oneplus },
    { text: "Xiaomi", image: xaiomi },
    { text: "Infinix", image: infinix },
    { text: "Vivo", image: vivo },
    { text: "Pixel", image: pixel },
    { text: "Nothing", image: nothing },
    { text: "Asus", image: asus },
    { text: "Itel", image: itel },
    { text: "Tecno", image: tecno },
    { text: "Sony", image: sony },
    { text: "Lenovo", image: lenovo },
    { text: "Huawei", image: huawei },
    { text: "Motorola", image: motorola },
    { text: "Nokia", image: nokia },
  ];

  return (
    <>
      <div className="branding">
        <div className="container brand-container">
          <div className="row mb-5">
            <div className="col text-center">
              <h1 style={{ fontSize: "24px", fontWeight: "800" }}>
                Explore By Brand
              </h1>
            </div>
          </div>
          <div className="row">
            {logos
              .slice(0, showAllLogos ? logos.length : 12)
              .map((logo, index) => {
                return (
                  <div className="col-4 col-lg-2 mb-5" key={index}>
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        to={`/${slugs.new_mobiles}?brand=${logo.text}&reset=reset`}
                      >
                        <div
                          className="bg-secondary d-flex justify-content-center align-items-center"
                          style={{ width: "90px", height: "90px" }}
                        >
                          <img
                            src={logo.image}
                            alt="logo"
                            height="16px"
                            width="63px"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row">
            <div className="col text-center">
              <button
                className="btn btn-primary"
                onClick={() => setShowAllLogos((prev) => !prev)}
              >
                {showAllLogos ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Branding;
