import "./aboutus.scss";
import { Link } from "react-router-dom";
import about_us_img from "../../../assets/about_us_hero.png";

const AboutUsBanner = () => {
  return (
    <div className="container-fluid">
      <div className="row mx-0 mb-5 mt-5 mt-md-2 z-2">
        <div className="col-12 offset-md-1 col-md-5 px-4 px-md-0 align-self-center">
          <nav
            aria-label="breadcrumb"
            className="mb-2 mb-md-2 mb-lg-5 mt-0 mt-md-5 mt-lg-5 mt-xl-0"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active text-info">About Us</li>
            </ol>
          </nav>
          <h1 className="heading-bold pb-4">About Us</h1>
          <p className="pb-0 pb-md-2">
            Welcome to Ibad Mobile – Your Ultimate Destination for Mobile
            Marketplace Excellence!
          </p>
          <p>
            Discover an array of mobile options, compare prices, and explore
            detailed product insights, all at your fingertips. Our mission is to
            simplify your buying journey, providing you with the information you
            need to make informed choices and find the perfect mobile device to
            fit your needs.
          </p>
          <a
            href="mailto:contact@ibadmobile.com"
            className="my-4 btn banner-btn"
          >
            CONTACT US
          </a>
        </div>
        <div className="offset-0 offset-sm-2 offset-md-0 col-12 col-sm-10 col-md-6 p-0 z-1">
          <img src={about_us_img} className="about_us_img" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutUsBanner;
