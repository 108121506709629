import React, { useEffect, useState, useRef } from "react";
import "./comparison.scss";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface MobileId {
  id: string;
}

interface ComparisonMobilesCard2Props {
  onSelectMobile: (mobileId: MobileId) => void;
  mobileId: string | null;
}

interface MobileData {
  id: string;
  modelName: string;
  imageUrls: [string];
  variants: any;
}

const ComparisonMobilesCard2: React.FC<ComparisonMobilesCard2Props> = ({
  onSelectMobile,
  mobileId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedMobile, setSelectedMobile] = useState<MobileData | null>(null);
  const [searchResults, setSearchResults] = useState<MobileData[]>([]);
  const mobiles = useSelector((state: any) => state.mobiles.mobiles);
  const currency = useSelector((state: RootState) => state.country.currency);

  // useEffect(() => {
  //   console.log("id");
  //   console.log(mobileId);
  // }, [mobileId]);

  useEffect(() => {
    if (mobileId) {
      const selectedMobileData = mobiles.find(
        (mobile: MobileData) => mobile.id === mobileId
      );
      setSelectedMobile(selectedMobileData || null);
      setInputValue(selectedMobileData?.modelName || "");
    } else {
      setSelectedMobile(null);
      setInputValue("");
    }
  }, [mobileId, mobiles]);

  useEffect(() => {
    if (inputValue) {
      const filteredMobiles = mobiles.filter((mobile: MobileData) =>
        mobile.modelName.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSearchResults(filteredMobiles);
    } else {
      setSearchResults([]);
    }
  }, [inputValue, mobiles]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSelectMobile = (mobile: MobileData) => {
    onSelectMobile({ id: mobile.id });
    setSelectedMobile(mobile);
    setInputValue(mobile.modelName);
    setSearchResults([]);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInputField = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const dropdown = document.querySelector(".search-results");
      if (dropdown && !dropdown.contains(event.target)) {
        setSearchResults([]);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="ComparisonMobilesCard2 mb-3">
      <div
        className="card border-0 mb-3 "
        style={{
          width: "auto",
          backgroundColor: "#f8f9fa",
          borderRadius: "20px",
        }}
      >
        <div
          className="card-body my-2 mx-3 px-0 px-md-2"
          style={{ minHeight: "90px", maxHeight: "90px" }}
        >
          <h6
            className="card-title compare__title"
            style={{ fontWeight: "500" }}
          >
            COMPARE WITH
          </h6>
          <input
            ref={inputRef} // Attach the ref here
            type="search"
            className="w-100 border-0 search__bar py-1 px-2 rounded "
            placeholder="Enter model name"
            value={inputValue}
            onChange={handleInputChange}
          />
          {searchResults.length > 0 &&
            inputValue != selectedMobile?.modelName && (
              <div
                className="search-results"
                // style={{
                //   maxHeight: "300px",
                //   overflowY: "auto",
                //   overflowX: "hidden",
                //   border: "1px solid darkgray",
                //   position: "relative",
                //   zIndex: "100",
                // }}
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  width: "100%",
                  maxWidth: "340px",
                  marginTop: "4px",
                  background: "#fff",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  border: "1px solid darkgray",
                  zIndex: 100,
                }}
              >
                <div
                  style={{
                    maxHeight: "270px",
                    overflowY: "auto",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {searchResults.map((mobile) => (
                    <div
                      key={mobile.id}
                      className="search-result-item"
                      onClick={() => handleSelectMobile(mobile)}
                      style={{ cursor: "pointer", backgroundColor: "#f8f9fa" }}
                    >
                      <div className="row search-result-row gx-0">
                        <div
                          className="col-4"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={mobile.imageUrls[0]}
                            alt={mobile.modelName}
                            style={{
                              maxWidth: "60px",
                              maxHeight: "60px",
                              padding: "8px 0px 8px 10px",
                            }}
                          />
                        </div>
                        <div className="col-8 d-flex align-items-center">
                          <span className="px-2">{mobile.modelName}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
      {selectedMobile ? (
        <div
          className="card d-flex align-items-center justify-content-center selected-mobile-card add__card "
          style={{
            backgroundColor: "#f8f9fa",
            width: "auto",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <img
            // src={selectedMobile.imageUrls[0]}
            src={`${selectedMobile.imageUrls[0]}?${new Date().getTime()}`} // Force image reload
            alt={selectedMobile.modelName}
            className="mob-img"
          />

          {selectedMobile.variants[0].priceInCurrency ===
            selectedMobile.variants[0].priceInUsd &&
          currency != "$" &&
          selectedMobile.variants[0].priceInUsd !==
            selectedMobile.variants[0].priceInGBP ? (
            <div className="card-body"></div>
          ) : (
            <div className="card-body" style={{ maxHeight: "120px" }}>
              <span
                style={{
                  fontSize: "16px",
                  color: "#adb5bd",
                  maxHeight: "70px",
                  textAlign: "center",
                }}
              >
                Market Price
              </span>
              <p
                style={{
                  fontSize: "24px",
                  maxHeight: "70px",
                  padding: "0px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {currency + " " + selectedMobile.variants[0].priceInCurrency}

                {/* {selectedMobile.variants[0].priceInCurrency ===
              selectedMobile.variants[0].priceInUsd
                ? "$"
                : currency}{" "}
              {selectedMobile.variants[0].priceInCurrency} */}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="card d-flex align-items-center justify-content-center add__card"
          style={{
            backgroundColor: "#f8f9fa",
            width: "auto",
            borderRadius: "20px",
            cursor: "pointer",
            overflow: "hidden",
          }}
          onClick={focusInputField}
        >
          <AddIcon style={{ fontSize: "16px", color: "#adb5bd" }} />
          <span style={{ fontSize: "12px", color: "#adb5bd" }}>
            Add A Device
          </span>
        </div>
      )}
    </div>
  );
};

export default ComparisonMobilesCard2;
