import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../../assets/ibadmobilemainlogowithoutname.png";
import "./footer.scss";
import { Link, useNavigate } from "react-router-dom";
import slugs from "./../../../src/config/slugConfig";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const changeTab = (tab: string) => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    navigate(tab);
  };
  return (
    <>
      <div className="footer py-4">
        <div className="container  ">
          <div className="row mt-md-4 ">
            <div className="col-12 col-md-6 col-lg-5 text-center text-lg-start pt-5">
              <img
                src={logo}
                alt=""
                width="50px"
                style={{ paddingBottom: "10px" }}
              />
              <p className="text-white">
                We're not just a platform for used mobile purchasing – we're the
                bridge connecting you to quality, affordability, and
                convenience. With a passion for technology and a commitment to
                customer satisfaction, we've curated a seamless experience that
                empowers you to own the mobile device you desire, without
                compromise.
              </p>
              {/* <h6 className="text-info">Subscribe For More Info</h6>
              <input type="text" className="rounded px-3 mt-2 py-2 w-50" />
              <div className="mt-2">
                <button className="btn btn-info">Subscribe</button>
              </div> */}
            </div>
            {/* information */}
            <div className="col-12 col-md-6 col-lg-2 text-center text-lg-start pt-5">
              <h5 className="text-info">Information</h5>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab("/");
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Home</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(slugs.new_mobiles);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Mobiles</span>
              </button>
              <button
                className="col-12 mt-2 custom-button  text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(`/${slugs.comparison}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Compare</span>
              </button>
              <button
                className="col-12 mt-2 custom-button  text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(`/${slugs.blogs}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Blogs</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.videos}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Videos</span>
              </button>
              <button
                className=" col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.about}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">About Us</span>
              </button>
              <button
                className=" col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.contact}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Contact Us</span>
              </button>
              <button
                className=" col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.privacy}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Privacy Policy</span>
              </button>
              <button
                className=" col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.terms}`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Terms & Conditions</span>
              </button>
            </div>
            {/* top Items */}
            <div className="col-12 col-md-6 col-lg-2 text-center text-lg-start pt-5">
              <h5 className="text-info">Top Brands</h5>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Apple`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Apple</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Samsung`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Samsung</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Oneplus`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">One plus</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Oppo`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Oppo</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Xiaomi`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Xiaomi</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab(`/${slugs.new_mobiles}?brand=Google`);
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Google</span>
              </button>
            </div>
            {/* others */}
            <div className="col-12 col-md-6 col-lg-3 text-center text-lg-start pt-5">
              <h5 className="text-info">Others</h5>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(
                    "/mobile-details/03myysu1qqzx/Xiaomi%20Redmi%20Note%2013"
                  );
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText text-white">Xiaomi Redmi Note 13</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(
                    "/mobile-details/4cwilprekoe/Apple%20iPhone%2015%20Pro%20Max"
                  );
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Apple iPhone 15 Pro Max</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start"
                onClick={() => {
                  changeTab(
                    "/mobile-details/9p5o9ene9k/Samsung%20Galaxy%20A33"
                  );
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">Samsung Galaxy A33</span>
              </button>
              <button
                className="col-12 mt-2 custom-button text-center text-white text-lg-start "
                onClick={() => {
                  changeTab("/mobile-details/8jfh9kbmiaw/OnePlus%2012R");
                }}
              >
                <span className="icon">&gt;</span>
                <span className="btnText">OnePlus 12R</span>
              </button>
            </div>
          </div>
          <hr />
          <div className="policy d-flex justify-content-between align-items-center">
            <p
              className="text-center text-lg-start mb-0"
              style={{ color: "white", maxWidth: "420px" }}
            >
              Copyright &copy; 2023 by Maiursoftwares{" "}
              <Link
                to={`/${slugs.terms}`}
                style={{ display: "inline", textDecoration: "underline" }}
              >
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                to={`/${slugs.privacy}`}
                style={{ display: "inline", textDecoration: "underline" }}
              >
                privacy policy
              </Link>{" "}
              must apply.
            </p>
            <div className="social-links d-flex ">
              <Link
                to="https://www.facebook.com/ibadmobile"
                target="_blank"
                className="bg-info  text-white  py-2 px-2 mx-1"
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <FacebookIcon />
              </Link>
              <Link
                to="https://www.instagram.com/ibad.mobile/"
                target="_blank"
                className="bg-info  text-white  py-2 px-2 mx-1"
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <InstagramIcon />
              </Link>
              <Link
                to="https://www.youtube.com/@ibadmobile-ku9vd"
                target="_blank"
                className="bg-info  text-white  py-2 px-2 mx-1"
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <YoutubeIcon />
              </Link>
              <Link
                to="mailto:contact@ibadmobile.com"
                target="_blank"
                className="bg-info  text-white  py-2 px-2 mx-1"
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <GoogleIcon />
              </Link>
              {/* <Link
                to="/"
                className=" bg-info text-white  py-2 px-2  mx-1"
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <PinterestIcon />
              </Link>
              <Link
                to="/"
                className="bg-info  text-white  py-2 px-2 "
                style={{ borderRadius: "100%", fontSize: "10px" }}
              >
                <TwitterIcon />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
