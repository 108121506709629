import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
// import { MobileData } from "../../NewMobiles/interface";
import { UsedMobileData } from "../interface";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CircleIcon from "@mui/icons-material/Circle";

interface UsedMobileCardGridProps {
  mobile: UsedMobileData;
}

const UsedMobileCardList: React.FC<UsedMobileCardGridProps> = ({ mobile }) => {
  const [rating, setRating] = useState<any>(0);
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  function timeAgo(postedDate: any) {
    const now = new Date(); // Current time
    const postDate = new Date(
      postedDate._seconds * 1000 + postedDate._nanoseconds / 1000000
    ); // Convert Firestore timestamp to JS Date

    const diffInMs = now.getTime() - postDate.getTime(); // Difference in milliseconds
    const diffInSeconds = Math.floor(diffInMs / 1000); // Difference in seconds

    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${
        diffInSeconds === 1 ? "second" : "seconds"
      } ago`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${
        diffInMinutes === 1 ? "minute" : "minutes"
      } ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} ${diffInWeeks === 1 ? "week" : "weeks"} ago`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
  }

  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2 ? 3 : result < 3 && result > 2 ? 4 : result;
  };

  const currency = useSelector((state: RootState) => state.country.currency);
  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles);
  //// FIX ////
  const mobileDetail = mobiles.find((m) => m.id === mobile.newMobileId); // Filter the specific mobile based on id
  //// FIX ////

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedVariant, setSelectedVariant] = useState(null);
  useEffect(() => {
    const minPrice = Number(searchParams.get("minPrice")) || 0;
    const maxPrice = Number(searchParams.get("maxPrice")) || 1000000;

    const foundVariant = mobileDetail.variants.find((variant: any) => {
      const price = parseFloat(variant.priceInCurrency);
      return price >= minPrice && price <= maxPrice;
    });

    setSelectedVariant(foundVariant);
  }, [searchParams]);

  return (
    <div
      className="card list__card mb-3"
      style={{
        maxHeight: "260px",
        backgroundColor: "#f8f9fa",
        borderRadius: "20px",
        border: "0",
      }}
    >
      <div className="row g-0">
        <div className="col-md-3  d-flex justify-content-center align-items-center">
          <img
            src={mobile.photos[0]}
            className="mt-3 mt-md-0   mx-auto"
            style={{
              mixBlendMode: "multiply",
              width: "97px",
              height: "96px",
              objectFit: "contain",
            }}
            alt="..."
          />
        </div>
        <div className="col-md-8 ">
          <div className="card-body">
            <h6
              style={{ fontSize: "16px", fontWeight: "400" }}
              className="card-title"
            >
              {mobile.title.length < 24
                ? mobile.title.substring(0, 24)
                : mobile.title.substring(0, 24) + "..."}
            </h6>
            <h5
              style={{ fontWeight: "500", fontSize: "20px" }}
              className="card-text"
            >
              PKR {mobile.variant.priceInPkr}
            </h5>

            <p className="mb-0 mt-2" style={{ fontSize: "12px" }}>
              <LocationOnOutlinedIcon
                style={{ fontSize: "18px", marginLeft: "-2px" }}
              />{" "}
              {mobile.city}
              <CircleIcon
                style={{ fontSize: "6px", color: "#A9A9A9", marginLeft: "6px" }}
              />{" "}
              <span style={{ color: "#A9A9A9" }}>
                {timeAgo(mobile.postedDate)}
              </span>
            </p>
            <div
              className="gap-2 d-flex align-items-center mt-2"
              style={{ maxWidth: "200px" }}
            >
              <button
                className="btn btn-sm btn-outline-info"
                style={{ borderRadius: "7px" }}
              >
                <LocalPhoneOutlinedIcon style={{ fontSize: "18px" }} />{" "}
                <span style={{ fontSize: "12px" }}>Contact Now</span>
              </button>
              <button
                className="btn btn-sm btn-info"
                style={{ borderRadius: "7px" }}
              >
                <TextsmsIcon style={{ fontSize: "18px" }} />{" "}
                <span style={{ fontSize: "12px" }}>Chat</span>
              </button>
            </div>
            {/* <div className="rating d-flex justify-content-start">
              <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                size={18}
                initialValue={getRating(mobile)}
              />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "300",
                  marginRight: "5px",
                  marginTop: "5px",
                }}
              >
                {mobile.mainCameraRating} reviews
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsedMobileCardList;
