import React, { useContext, useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Frontend from './Frontend';
import Auth from './Auth'
import { AuthContext } from '../context/AuthContext';



const Index: React.FC = () => {
    const { isAuthenticated }: any = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true);
    return (
        <>
   
                <Routes>
                    <Route path='/*' element={<Frontend />} />
                    <Route path='/auth/*' element={!isAuthenticated ? <Auth /> : <Navigate to='/' />} />
                </Routes>
         
        </>
    );
};

export default Index;
