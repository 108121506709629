import { Link, useParams } from "react-router-dom";
import "./blogPost.scss";
import Profile_icon from "../../../../assets/demo_profile_icon.png";
import { BlogDataInterface, formatDateFromTimestamp } from "../utils";
import slugs from "../../../../config/slugConfig";

interface BlogPostProps {
  blogPost: BlogDataInterface;
}

const BlogPost: React.FC<BlogPostProps> = ({ blogPost }) => {
  return (
    <>
      <div className="row mb-4 mt-5 blogpost">
        <div className="col">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb mb-0">
              <li
                className="breadcrumb-item"
                style={{ fontSize: "12px", fontWeight: "300" }}
              >
                <Link className="text-dark" to="/">
                  Home
                </Link>
              </li>
              <li
                className={`breadcrumb-item active fw-normal`}
                aria-current="page"
                style={{
                  fontSize: "12px",
                  minWidth: "92px",
                }}
              >
                <Link className="text-info" to={`/${slugs.blogs}`}>
                  Blogs
                </Link>
              </li>
            </ol>
          </nav>
          <h3
            className="mt-2"
            style={{
              fontFamily: "Roboto",
              fontWeight: "800",
              fontSize: "24px",
            }}
          >
            News Item: {blogPost.title}
          </h3>
          <div className="outer-desc-div">
            <div className="inner-desc-div">
              <img src={Profile_icon} alt="" />
              <p>
                <small>{blogPost.postedBy}</small>
              </p>
            </div>
            <div className="inner-desc-div">
              <p>
                <small>{blogPost.readTime} read</small>
              </p>
              <p>.</p>
              <p>
                <small>{formatDateFromTimestamp(blogPost.date)}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={blogPost.image}
          alt=""
          style={{
            borderRadius: "30px",
            width: "fit-content",
            objectFit: "contain",
            maxHeight: "500px",
            // height: "100%",
            // objectPosition: "center",
          }}
        />
      </div>

      <div className="mt-5 blogpost_content">
        {blogPost.cssString && <StyleInjector cssString={blogPost.cssString} />}
        <div dangerouslySetInnerHTML={{ __html: blogPost.htmlContent }} />
      </div>
    </>
  );
};

export default BlogPost;

const StyleInjector = ({ cssString }: { cssString: string }) => {
  return <style dangerouslySetInnerHTML={{ __html: cssString }} />;
};
