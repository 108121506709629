import "./mobile1.scss";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
// import { MobileData } from "../../NewMobiles/interface";
import { UsedMobileData } from "../interface";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CircleIcon from "@mui/icons-material/Circle";

interface UsedMobileCardGridProps {
  mobile: UsedMobileData; // Define the prop to accept mobile data
}
const UsedMobileCardGrid: React.FC<UsedMobileCardGridProps> = ({ mobile }) => {
  const [rating, setRating] = useState<any>(0);
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  const currency = useSelector((state: RootState) => state.country.currency);
  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles);
  //// FIX ////
  const mobileDetail = mobiles.find((m) => m.id === mobile.newMobileId); // Filter the specific mobile based on id
  //// FIX ////

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedVariant, setSelectedVariant] = useState(null);
  //// FIX ////
  // useEffect(() => {
  //   const minPrice = Number(searchParams.get("minPrice")) || 0;
  //   const maxPrice = Number(searchParams.get("maxPrice")) || 1000000;

  //   const foundVariant = mobileDetail.variants.find((variant: any) => {
  //     const price = parseFloat(variant.priceInCurrency);
  //     return price >= minPrice && price <= maxPrice;
  //   });

  //   setSelectedVariant(foundVariant);
  // }, [searchParams]);
  //// FIX ////

  function timeAgo(postedDate: any) {
    const now = new Date(); // Current time
    const postDate = new Date(
      postedDate._seconds * 1000 + postedDate._nanoseconds / 1000000
    ); // Convert Firestore timestamp to JS Date

    const diffInMs = now.getTime() - postDate.getTime(); // Difference in milliseconds
    const diffInSeconds = Math.floor(diffInMs / 1000); // Difference in seconds

    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${
        diffInSeconds === 1 ? "second" : "seconds"
      } ago`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${
        diffInMinutes === 1 ? "minute" : "minutes"
      } ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} ${diffInWeeks === 1 ? "week" : "weeks"} ago`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
  }

  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2 ? 3 : result < 3 && result > 2 ? 4 : result;
  };
  return (
    <div className="mobile-card card grid__card">
      <div className="mt-md-1  d-flex justify-content-center align-items-center py-3 pt-md-2 pb-md-0 overflow-hidden ">
        <img
          src={mobile.photos[0]}
          style={{ mixBlendMode: "multiply" }}
          height="114px"
          alt="mobile"
        />
      </div>
      <div className="card-body">
        <h6 style={{ fontWeight: "400" }}>
          {mobile.title.length < 24
            ? mobile.title.substring(0, 24)
            : mobile.title.substring(0, 24) + "..."}
        </h6>
        <div
          className="price_rating"
          // style={{ position: "absolute", bottom: "22px" }}
        >
          <div className=" d-flex justify-content-between align-items-center ">
            <h5 style={{ fontWeight: "500" }} className="price mb-0">
              PKR {mobile.variant.priceInPkr}
            </h5>
            {/* <FavoriteBorderOutlinedIcon className="text-info" /> */}
          </div>
          <p className="mb-0 mt-2" style={{ fontSize: "12px" }}>
            <LocationOnOutlinedIcon
              style={{ fontSize: "18px", marginLeft: "-4px" }}
            />{" "}
            {mobile.city}
            <CircleIcon
              style={{ fontSize: "6px", color: "#A9A9A9", marginLeft: "6px" }}
            />{" "}
            <span style={{ color: "#A9A9A9" }}>
              {timeAgo(mobile.postedDate)}
            </span>
          </p>
          <div className="  d-flex justify-content-center align-items-center mt-2 gap-2">
            <button
              className="btn btn-sm btn-outline-info"
              style={{ borderRadius: "7px" }}
            >
              <LocalPhoneOutlinedIcon style={{ fontSize: "18px" }} />{" "}
              <span style={{ fontSize: "12px" }}>Contact Now</span>
            </button>
            <button
              className="btn btn-sm btn-info"
              style={{ borderRadius: "7px" }}
            >
              <TextsmsIcon style={{ fontSize: "18px" }} />{" "}
              <span style={{ fontSize: "12px" }}>Chat</span>
            </button>
          </div>
          {/* <div className="rating d-flex ">
            <Rating
              onClick={handleRating}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              onPointerMove={onPointerMove}
              size={16}
              className="rating"
              initialValue={getRating(mobile)}
            />
            <p
              className="reviews ms-2"
              style={{
                fontSize: "14px",
                fontWeight: "300",
                marginTop: "5px",
                marginBottom: "0px",
                minWidth: "100px",
              }}
            >
              {mobile.mainCameraRating} reviews
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default UsedMobileCardGrid;
