import React, { createContext, useContext, useEffect, useReducer, useState, ReactNode } from 'react';
import { User, onAuthStateChanged, getIdToken, signOut } from 'firebase/auth';
import { auth, db } from '../config/firebase'; // Ensure db is imported from your firebase config
import { doc, getDoc } from 'firebase/firestore';

// Extend User interface (optional)
interface ExtendedUser extends User {
  fullName?: string;
  mobileNumber?: string;
}

interface AuthState {
  isAuthenticated: boolean;
  user?: ExtendedUser | null;
}

type AuthAction =
  | { type: 'LOGIN'; payload: { user: ExtendedUser } }
  | { type: 'LOGOUT' };

interface AuthContextType extends AuthState {
  dispatch: React.Dispatch<AuthAction>;
  isAppLoading: boolean;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const initialState: AuthState = { isAuthenticated: false, user: null };

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { isAuthenticated: true, user: action.payload.user };
    case 'LOGOUT':
      return { isAuthenticated: false, user: null };
    default:
      return state;
  }
};

export default function AuthContextProvider(props: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAppLoading, setIsAppLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch additional user details from Firestore
        const userDoc = await getDoc(doc(db, "users", user.uid));
        let userData: ExtendedUser = user;

        if (userDoc.exists()) {
          const userDetails = userDoc.data();
          userData = {
            ...user,
            fullName: userDetails.fullName,
            mobileNumber: userDetails.mobileNumber,
          };
        }

        dispatch({ type: 'LOGIN', payload: { user: userData } });
        const userToken = await getIdToken(user);
        localStorage.setItem('firebaseToken', userToken);
      } else {
        dispatch({ type: 'LOGOUT' });
        localStorage.removeItem('firebaseToken');
      }
      setIsAppLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    signOut(auth);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider value={{ ...state, dispatch, isAppLoading, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
}