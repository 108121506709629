import React, { useEffect, useState } from "react";
import "./comparison.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { MobileData } from "../NewMobiles/interface";

interface ComparisonTableProps {
  selectedMobileIds: (string | null)[];
  cols: number;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({
  selectedMobileIds,
  cols,
}) => {
  const mobiles = useSelector((state: any) => state.mobiles.mobiles); // Adjust according to your Redux store
  const currency = useSelector((state: RootState) => state.country.currency);
  const country = useSelector((state: RootState) => state.country.country);
  // const countryKey = country as "PK" | "US" | "IN" | "UAE" | "DE";
  const countryKey = country as "PK" | "US" | "IN" | "UAE" | "EUR";

  const currencyCodes = {
    PK: "PKR",
    US: "USD",
    IN: "INR",
    UAE: "AED",
    EUR: "EUR",
    // DE: "EUR",
  };

  const getMobileDataById = (id: string | null) => {
    return id ? mobiles.find((mobile: MobileData) => mobile.id === id) : null;
  };

  const selectedMobileData = selectedMobileIds.map(getMobileDataById);

  // console.log("selectedMobileData");
  // console.log(selectedMobileData);
  return (
    <div className="container-fluid">
      <div className="offset-1 col-10 offset-lg-0 col-lg-12">
        <div className="ComparisonTable">
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-secondary ">
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={18} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      General Features
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      General Features
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Release Date</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary">Release Date</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.dateCreated ? (
                            typeof mobile.dateCreated === "string" ? (
                              new Date(mobile.dateCreated).toLocaleDateString(
                                "en-GB"
                              ) // UK format: day/month/year
                            ) : (
                              new Date(
                                mobile.dateCreated._seconds * 1000
                              ).toLocaleDateString("en-GB")
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Sim Support</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Sim Support</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.sim
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Phone Dimensions
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Phone Dimensions</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.dimensions
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Phone Weight</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Phone Weight</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.weight + " g"
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Operating System
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Operating System</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? mobile.os : <span className="dull">N/A</span>}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={12} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Display
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Display
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Screen Size</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Screen Size</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.size
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Screen Resolution
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Screen Resolution</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.resolution
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Screen Type</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Screen Type</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.displayType
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Screen Protection
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Screen Protection</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          // @ts-ignore
                          mobile.screenProtection ? (
                            // @ts-ignore
                            mobile.screenProtection
                          ) : (
                            "Not Specified"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={9} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Memory
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Memory
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Internal Storage
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Internal Storage</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          [
                            ...new Set(
                              mobile.variants.map((m) =>
                                m.internalStorage === 1000
                                  ? "1024 gb"
                                  : m.internalStorage + " gb"
                              )
                            ),
                          ]
                            .sort(
                              (a, b) =>
                                +a.replace("gb", "") - +b.replace("gb", "")
                            )
                            .join(", ")
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Ram</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Ram</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          [
                            ...new Set(
                              mobile.variants.map((m) => m.ram + " gb")
                            ),
                          ]
                            .sort(
                              (a, b) =>
                                +a.replace("gb", "") - +b.replace("gb", "")
                            )
                            .join(", ")
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Card Slot</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Card Slot</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.cardSlot == "true" ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={6} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Performance
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Performance
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Processor</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Processor</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.cpu
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">GPU</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">GPU</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.gpu
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={3} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Battery
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Battery
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Type</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Type</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.battery
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={21} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Camera
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Camera
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Front Camera</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Front Camera</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.frontCamera
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Front Flash Light
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Front Flash Light</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.frontCamera.toLowerCase().includes("flash") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Front Video Recording
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Front Video Recording</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.frontCamera.toLowerCase().includes("video") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Back Camera</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Back Camera</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.mainCamera
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Back Flash Light
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Back Flash Light</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.mainCamera.toLowerCase().includes("flash") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">
                      Back Video Recording
                    </h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Back Video Recording</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.mainCameraFeatures
                            .toLowerCase()
                            .includes("video") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={24} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Connectivity
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Connectivity
                    </h6>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Bluetooth</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Bluetooth</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.bluetooth == "true" ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">3G</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">3G</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.network.includes("3G") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">4G/LTE</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">4G/LTE</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.network.includes("4G") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">5G</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">5G</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.network.includes("5G") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Radio</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Radio</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.radio == "true" ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Wifi</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">Wifi</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.wifiTechnology
                            .toLowerCase()
                            .includes("wi-fi") ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>

                <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">NFC</h6>
                  </td>
                </tr>
                <tr>
                  <th className="bg-secondary ">NFC</th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          mobile.nfc == "YES" ? (
                            "Yes"
                          ) : (
                            "No"
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
              <tbody className="tbody">
                <tr className="fw-bold features__mobile">
                  <td rowSpan={24} className="side-col">
                    <h6
                      className="bg-secondary py-3 px-3"
                      style={{ borderRadius: "10px" }}
                    >
                      Price
                    </h6>
                  </td>
                  <td colSpan={3} className="top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0 my-2 mx-3">
                      Price
                    </h6>
                  </td>
                </tr>

                {/* <tr>
                  <td colSpan={3} className="sec-top-col">
                    <h6 className="bg-secondary py-3 px-3 m-0">Price</h6>
                  </td>
                </tr> */}
                <tr>
                  <th className="bg-secondary">
                    {currencyCodes[`${countryKey}`]}
                  </th>
                  {selectedMobileData
                    .slice(0, cols)
                    .map((mobile: MobileData, index) => (
                      <td key={index}>
                        {mobile ? (
                          // mobile.variants
                          //   .map(
                          //     (m) =>
                          //       `<b>${currency} ${m.priceInCurrency}</b> ${m.ram}/${m.internalStorage} gb`
                          //   )
                          //   .join(",<br/> ")

                          <div style={{ lineHeight: "30px" }}>
                            {mobile.variants.map((m, variantIndex) => (
                              <React.Fragment key={variantIndex}>
                                {variantIndex > 0 && ","}
                                {variantIndex > 0 && <br />}
                                <b>
                                  {currency} {m.priceInCurrency}
                                </b>{" "}
                                ({m.ram}/{m.internalStorage} gb)
                              </React.Fragment>
                            ))}
                          </div>
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;
