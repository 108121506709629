import { Helmet } from "react-helmet";
import "./profile.scss";
import Breadcrumb from "./Breadcrumb";
import Profile from "./Profile";

const UserProfile = () => {
  return (
    <div className="container my-5 user-profile-parent">
      <Helmet>
        <title>User Profile | Ibad Mobile</title>
        {/* <meta name="description" content="" />
        <meta name="keywords" content="" /> */}
      </Helmet>
      <Breadcrumb />
      <Profile />
    </div>
  );
};

export default UserProfile;
