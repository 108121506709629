export interface BlogDataInterface {
  id: string;
  company: string;
  cssString: string;
  date: { _seconds: number; _nanoseconds: number };
  htmlContent: string;
  image: string;
  modelName: string;
  postedBy: string;
  readTime: string;
  recommended: boolean;
  summary: string;
  tags: string[];
  thumbnail: string;
  title: string;
}


export function formatDateFromTimestamp(timestamp: {
  _seconds: number;
  _nanoseconds: number;
}) {
  const date = new Date(timestamp._seconds * 1000);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}