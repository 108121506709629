import { useState } from "react";
import { StateProps, scrollToTop } from "./ParentStates";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { Country, State, City, IState, ICity } from "country-state-city";

const State4 = ({
  activeState,
  setActiveState,
  userAdData,
  setUserAdData,
}: StateProps) => {
  const [selectedProvince, setSelectedProvince] = useState(
    userAdData.province || ""
  );
  const [selectedCity, setSelectedCity] = useState(userAdData.city || "");
  const [selectedTown, setSelectedTown] = useState(userAdData.town || "");
  const [error, setError] = useState("");

  const provinces: IState[] = State.getStatesOfCountry("PK"); // 'PK' is the ISO code for Pakistan
  const cities: ICity[] = selectedProvince
    ? City.getCitiesOfState("PK", selectedProvince)
    : [];

  const handleContinue = () => {
    if (!selectedProvince || !selectedCity || !selectedTown) {
      setError("All fields are not selected");
      return;
    }

    setError("");
    setUserAdData((prevData) => ({
      ...prevData,
      province: selectedProvince,
      city: selectedCity,
      town: selectedTown,
    }));
    setActiveState(5);
    scrollToTop();

    // console.log({
    //   ...userAdData,
    //   province: selectedProvince,
    //   city: selectedCity,
    //   town: selectedTown,
    // });
  };

  return (
    <>
      <div className="row parent-top-banner-state4 m-2 m-md-0">
        <h5 className="head-title">Your Ad's Location</h5>
        {error && <div className="col-12 text-danger">{error}</div>}

        <div className="col-12 col-md-4">
          <label htmlFor="province-dropdown">Province</label>
          <select
            name="province-dropdown"
            id="province-dropdown"
            value={selectedProvince}
            onChange={(e) => {
              setSelectedProvince(e.target.value);
              setSelectedCity(""); // Reset city and town when province changes
              setSelectedTown("");
            }}
          >
            <option value="" disabled>
              Select Province
            </option>
            {provinces.map((province) => (
              <option key={province.isoCode} value={province.isoCode}>
                {province.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-4">
          <label htmlFor="city-dropdown">City</label>
          <select
            name="city-dropdown"
            id="city-dropdown"
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              setSelectedTown(""); // Reset town when city changes
            }}
            disabled={!selectedProvince}
          >
            <option value="" disabled>
              Select City
            </option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-4">
          <label htmlFor="town-dropdown">Town</label>
          <input
            type="text"
            id="town-dropdown"
            value={selectedTown}
            onChange={(e) => setSelectedTown(e.target.value)}
            disabled={!selectedCity}
            placeholder="Enter Town"
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <p className="text-info">
            <LightbulbIcon />
          </p>
          <p>
            Post your ad on our platform and effortlessly edit or update it
            anytime
          </p>
        </div>
        <div className="button-parent">
          {/* <button
            className="btn btn-info"
            onClick={() => {
              setActiveState(3);
              scrollToTop();
            }}
          >
            Previous
          </button> */}
          <button
            className="btn btn-info"
            onClick={handleContinue}
            style={{ marginLeft: "10px" }}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default State4;
