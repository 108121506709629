import { useEffect } from "react";
import loader from "../../assets/Screenloader.gif";
import "./screenloader.scss";

const ScreenLoader = () => {
  useEffect(() => {
    // Add the class to the body element to disable scrolling
    document.body.classList.add('no-scroll');

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="screenLoader">
      <img src={loader} alt="loading..." />
    </div>
  );
};

export default ScreenLoader;

// import React from "react";
// import loader from "../../assets/Screenloader.gif";
// import "./screenloader.scss";

// const ScreenLoader = () => {
//   return (
//     <div className="screenLoader">
//       <img src={loader} alt="loading..." />
//     </div>
//   );
// };

// export default ScreenLoader;