import { Link } from "react-router-dom";
import Profile_icon from "../../../assets/demo_profile_icon.png";
import { BlogDataInterface, formatDateFromTimestamp } from "./utils";
import slugs from "../../../config/slugConfig";

const BlogCard = ({
  blog,
  hotTopic,
  firstItem,
}: {
  blog: BlogDataInterface;
  hotTopic?: boolean;
  firstItem?: boolean;
}) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const blogDate = new Date(blog.date._seconds * 1000);
  const isOfLastSevenDays = blogDate >= sevenDaysAgo;

  return (
    <Link
      className={`card blog-card ${
        firstItem ? "blog-card-first" : "blog-card-non-first"
      }`}
      to={`/${slugs.blogs}/${blog.title}`}
      // style={{ height: firstItem ? "100%" : "auto" }}
    >
      <div className="blog-image">
        <img src={blog.image} className="blog-image" alt="..." />
        <div className="overlay"></div>
      </div>
      <div className="card-body">
        {isOfLastSevenDays && (
          <button className="latest-news-btn">Latest News</button>
        )}

        <h4 className="card-title">
          {hotTopic && "Hot Topic:"} {blog.title}
        </h4>

        <div className="outer-desc-div">
          <div className="inner-desc-div">
            <img src={Profile_icon} alt="" />
            <p className="card-text">
              <small>{blog.postedBy}</small>
            </p>
          </div>
          <p className="card-text">
            <small>{formatDateFromTimestamp(blog.date)}</small>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
