import "./mobiles.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { MobileData } from "../NewMobiles/interface";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import slugs from "../../../config/slugConfig";

interface filters {
  brand: string[];
  minPrice: number;
  maxPrice: number;
  recommended: boolean;
  screenSize: string[];
  ram: number[];
  storage: number[];
  sort: string;
}
interface FiltersProps {
  filters: filters;
  applyFilters: Function;
  viewMode: string;
  setViewMode: Function;
  documents: MobileData[];
  showFiltersSidebar: boolean;
  setShowFiltersSidebar: any;
  setFiltersApplied: any;
  resetFilters: Function;
}

const TopFilters: React.FC<FiltersProps> = ({
  filters,
  applyFilters,
  viewMode,
  setViewMode,
  documents,
  showFiltersSidebar,
  setShowFiltersSidebar,
  setFiltersApplied,
  resetFilters,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [mobileId, setMobileId] = useState<string[]>([]);

  // Click handler for switching to List View
  const handleGridViewClick = () => {
    setViewMode("grid");
  };
  const handleListViewClick = () => {
    setViewMode("list");
  };
  const handleSearchInputChange = async (query: string) => {
    setSearchQuery(query);

    const filteredMobiles = documents.filter((mobile: MobileData) =>
      mobile.modelName.toLowerCase().includes(query.toLowerCase())
    );
    const modelNames = filteredMobiles.map((mobile) => mobile.modelName);
    const mobileIds = filteredMobiles.map((mobile) => mobile.id);
    const imageUrls = filteredMobiles.flatMap((mobile) => mobile.imageUrls);

    setImageUrls(imageUrls);
    setMobileId(mobileIds);
    setSuggestions(modelNames);
    setShowAllSuggestions(query.length > 0);

    if (query.length == 0) {
      const queryParams = new URLSearchParams();
      queryParams.append("search", "");

      const newURL = `/${slugs.new_mobiles}?${queryParams.toString()}`;
      navigate(newURL);
    }
  };
  const performSearch = () => {
    resetFilters();
    const queryParams = new URLSearchParams();
    queryParams.append("search", searchQuery);

    const newURL = `/${slugs.new_mobiles}?${queryParams.toString()}`;
    navigate(newURL);
  };
  const handleSearchIconClick = () => {
    const queryParamsPrev = new URLSearchParams(location.search);
    if (searchQuery != queryParamsPrev.get("search")) {
      performSearch();
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get("search") || "";
    setSearchQuery(searchParam);
  }, [location.search]); // Dependency on location.search to re-run this effect if the query parameters change

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const dropdown = document.querySelector(".suggestion-dropdown");
      if (dropdown && !dropdown.contains(event.target)) {
        setShowAllSuggestions(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center justify-content-between text-start mb-3">
          <button
            className="btn filters_btn"
            style={{ fontSize: "16px", fontWeight: "500" }}
            onClick={() => {
              setShowFiltersSidebar(true);
            }}
          >
            <SyncAltIcon
              className="me-2"
              style={{ width: "22px", height: "22px" }}
            />{" "}
            FILTERS
          </button>
          <div
            className="search__filtering d-flex align-items-center"
            style={{ position: "relative" }}
          >
            {/* <p className='search__keywords' style={{ fontSize: '14px', fontWeight: '500', marginTop: '3px', marginBottom: 0 }}>Search By Keyword:</p> */}
            <input
              type="search"
              name=""
              id=""
              placeholder="Search Here..."
              value={searchQuery} // Controlled input
              onChange={(e) => handleSearchInputChange(e.target.value)}
              className="form-control"
              style={{
                margin: "0px 5px",
                padding: "1px 5px",
                width: "147px",
                height: "30px",
                borderRadius: "7px",
              }}
            />
            <button
              className="btn btn-info"
              style={{ padding: "0px 3px", borderRadius: "7px" }}
              onClick={handleSearchIconClick}
            >
              <SearchIcon />
            </button>
            {showAllSuggestions && suggestions.length > 0 && (
              <div
                className="suggestion-dropdown"
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%",
                  background: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  zIndex: 10,
                  cursor: "pointer",
                  marginTop:"6px"
                }}
              >
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {suggestions.map((suggestion, index) => {
                    const imageUrl = imageUrls[index];
                    const id = mobileId[index];
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          navigate(`/mobile-details/${id}/${suggestion}`)
                        }
                        className="suggestion-item hover-div"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={imageUrl}
                            alt={suggestion}
                            style={{
                              maxHeight: "70px",
                              maxWidth: "60px",
                              objectFit: "contain",
                              padding: "0 6px",
                            }}
                          />
                        </div>
                        <p style={{ width: "70%", paddingLeft: "10px" }}>
                          {suggestion}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {/* <div className="text-center my-2">
                  <button
                    className="btn btn-info"
                    style={{ padding: "0px 3px", borderRadius: "7px" }}
                    onClick={handleSearchIconClick}
                  >
                    Show All Results
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <div className="col  ">
          <div className="search__filtering d-flex flex-wrap justify-content-between justify-content-md-center">
            <div className="d-flex">
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginTop: "3px",
                  minWidth: "50px",
                }}
              >
                Sort By:
              </p>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                style={{
                  fontSize: "14px",
                  margin: "0px 9px",
                  padding: "1px 7px",
                  width: "190px",
                  height: "30px",
                  borderRadius: "7px",
                }}
                onChange={
                  (e) => applyFilters("sort", e.target.value)
                }
                value={filters.sort || ""}
              >
                <option value="desc">Price High to Low</option>
                <option value="asc">Price Low to High</option>
                <option value="ram_asc">RAM Low to High</option>
                <option value="ram_desc">RAM High to Low</option>
                <option value="rom_asc">Storage Low to High</option>
                <option value="rom_desc">Storage High to Low</option>
                <option value="date_desc">Release Date (Newest)</option>
                <option value="date_asc">Release Date (Oldest)</option>
              </select>
            </div>

            <div className="d-flex">
              {/* Grid View filtering */}
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginLeft: "2px",
                }}
              >
                View:
              </p>
              <Tooltip title="List View" placement="top">
                <button
                  className={`btn ${
                    viewMode === "list" ? "active" : ""
                  } border-0`}
                  onClick={handleListViewClick}
                  style={{
                    marginLeft: "4px",
                    marginBottom: "9px",
                    padding: "0px 3px",
                    borderRadius: "7px",
                  }}
                >
                  <ListOutlinedIcon
                    style={{
                      fontSize: "27px",
                      color: viewMode === "list" ? "#7B2CBF" : "",
                    }}
                  />
                </button>
              </Tooltip>
              <Tooltip title="Grid View" placement="top">
                <button
                  className={`btn ${
                    viewMode === "grid" ? "active" : ""
                  } border-0`}
                  onClick={handleGridViewClick}
                  style={{
                    marginLeft: "2px",
                    marginBottom: "9px",
                    padding: "0px 3px",
                    borderRadius: "7px",
                  }}
                >
                  <GridViewOutlinedIcon
                    style={{
                      fontSize: "20px",
                      color: viewMode === "grid" ? "#7B2CBF" : "",
                    }}
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopFilters;
