import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ComparisonAdvertisingCard from "./ComparisonAdvertisingCard";
import ComparisonMobilesCard from "./ComparisonMobilesCard";
import ComparisonMobilesCard2 from "./ComparisonMobilesCard2";
import ComparisonTable from "./ComparisonTable";
import "./comparison.scss";
import { useSelector } from "react-redux";
import slugs from "./../../../../src/config/slugConfig";
import { MobileData } from "../../../Component/FeaturedMobile/interface2";

const ComparisonMobiles: React.FC = () => {
  interface MobileId {
    id: string;
  }

  // get mobile id from query parametes
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const modelNameQuery = query.get("model");

  const modelNames = modelNameQuery ? modelNameQuery.split(",") : [];

  const mobiles: MobileData[] = useSelector(
    (state: any) => state.mobiles.mobiles
  );
  const [selectedMobiles, setSelectedMobiles] = useState<Array<string | null>>([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    if (mobiles.length > 0 && modelNameQuery) {
      const modelNames = modelNameQuery.split(",");

      let ids = modelNames
        .map((modelName) => {
          const mobile = mobiles.find(
            (mobile) => mobile.modelName === modelName
          );
          return mobile ? mobile.id : null;
        })
        .filter((id) => id != null);

      // console.log("ids");
      // console.log(ids);

      if (ids.length == 1) {
        ids = [ids[0], null, null];
      } else if (ids.length == 2) {
        ids = [ids[0], ids[1], null];
      }
      setSelectedMobiles(ids.length > 0 ? ids : [null, null, null]);
    }
  }, [mobiles, modelNameQuery]);

  useEffect(() => {
    if (mobiles.length > 0) {
      const modelNames = selectedMobiles
        .map((id) => {
          const mobile = mobiles.find((mobile) => mobile.id === id);
          return mobile ? mobile.modelName : null;
        })
        .filter((modelName) => modelName != null)
        .join(",");

      const currentModelNames = query.get("model");
      if (modelNames !== currentModelNames) {
        // Ensure there's something to navigate with
        const newURL = `/${slugs.comparison}?model=${encodeURIComponent(modelNames)}`;
        navigate(newURL);
      }
    }
  }, [selectedMobiles, mobiles]);

  const handleSelectMobile = (mobileIdObj: MobileId, index: number) => {
    const newSelectedMobiles = [...selectedMobiles];
    newSelectedMobiles[index] = mobileIdObj.id; // Extract the string ID from the MobileId object
    setSelectedMobiles(newSelectedMobiles);
  };

  const [cols, setCols] = useState(window.innerWidth >= 992 ? 3 : 2);

  useEffect(() => {
    // Function to update cols based on window width
    const handleResize = () => {
      setCols(window.innerWidth >= 992 ? 3 : 2);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="ComparisonMobiles my-5">
      <div className="container">
        <div className="row mb-5 mt-5">
          <div className="col">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb mb-0">
                <li
                  className="breadcrumb-item"
                  style={{ fontSize: "12px", fontWeight: "300" }}
                >
                  <Link className="text-dark" to="/">
                    Home
                  </Link>
                </li>
                <li
                  className="breadcrumb-item "
                  style={{ fontSize: "12px", fontWeight: "300" }}
                >
                  <Link
                    to={`/${slugs.new_mobiles}?reset=reset`}
                    className="text-dark d-inline"
                  >
                    New Mobiles
                  </Link>
                </li>
                {/* <li
                  className="breadcrumb-item "
                  aria-current="page"
                  style={{ fontSize: "12px", fontWeight: "300" }}
                >
                  {" "}
                  <a href="#" className="text-dark d-inline">
                    Apple iPhone
                  </a>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Apple iPhones 14
                </li> */}
                <li
                  className="breadcrumb-item active"
                  // aria-current="page"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <Link
                    to={`/${slugs.comparison}`}
                    className="text-info"
                    style={{
                      display: "inline",
                    }}
                  >
                    Comparison
                  </Link>
                  {/* Compare Apple iPhones 14 */}
                </li>
              </ol>
            </nav>
            <h3
              className="mt-2"
              style={{
                fontFamily: "Roboto",
                fontWeight: "800",
                fontSize: "24px",
              }}
            >
              Compare Mobiles
            </h3>
            {/* <h3
              className="mt-2"
              style={{
                fontFamily: "Roboto",
                fontWeight: "800",
                fontSize: "24px",
              }}
            >
              Compare Apple iPhones 14{" "}
            </h3> */}
          </div>
        </div>
        <div className="row mb-5 flex justify-content-center justify-content-md-around px-2">
          {/* <div className={`col d-none d-lg-block gx-2 col-lg-3`} > */}
          {/* <div className={`col ${cols == 2 ? "d-none" : "d-block"} gx-2 col-lg-3`} > */}
          <div className={`col col-lg-3 gx-2 tablet-view-none`}>
            <ComparisonAdvertisingCard />
          </div>
          {selectedMobiles &&
            selectedMobiles.slice(0, cols).map((_, index) => (
              <div key={index} className="col-6 col-lg-3 gx-3 gx-lg-2">
                <ComparisonMobilesCard2
                  onSelectMobile={(mobileId) =>
                    handleSelectMobile(mobileId, index)
                  }
                  mobileId={
                    index === 0
                      ? selectedMobiles[0]
                      : index === 1
                      ? selectedMobiles[1]
                      : index === 2
                      ? selectedMobiles[2]
                      : null
                  }
                  // mobileId={index === 0 ? id : null}
                />
              </div>
            ))}
        </div>
        <div className="row">
          <ComparisonTable selectedMobileIds={selectedMobiles} cols={cols} />
        </div>
      </div>
    </div>
  );
};

export default ComparisonMobiles;
