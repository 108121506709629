import { Link } from "react-router-dom";
import slugs from "../../../../config/slugConfig";

const Breadcrumb = () => {
  return (
    <div className="row mb-4 mt-5">
      <div className="col">
        <nav aria-label="breadcrumb ">
          <ol className="breadcrumb mb-0">
            <li
              className="breadcrumb-item"
              style={{ fontSize: "12px", fontWeight: "300" }}
            >
              <Link className="text-dark" to="/">
                Home
              </Link>
            </li>
            <li
              className="breadcrumb-item "
              style={{ fontSize: "12px", fontWeight: "300" }}
            >
              {/* <Link to={`/${slugs.new_mobiles}`} className="text-dark d-inline"> */}
              <p className="text-dark d-inline">Used Mobiles</p>
            </li>
            <li
              className="breadcrumb-item active"
              // aria-current="page"
              style={{
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              <p
                // to={`/${slugs.comparison}`}
                className="text-info"
                style={{
                  display: "inline",
                }}
              >
                Sell Your Phone
              </p>
              {/* Compare Apple iPhones 14 */}
            </li>
          </ol>
        </nav>
        <h3
          className="mt-2"
          style={{
            fontFamily: "Roboto",
            fontWeight: "800",
            fontSize: "24px",
          }}
        >
          Sell Your Phone Online in Pakistan Instantly!
        </h3>
        {/* <p
          className="mt-3"
          style={{
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "16px",
          }}
        >
          Discover the freshest mobile tech updates, news, and trends. Make
          smarter device choices today!
        </p> */}
      </div>
    </div>
  );
};

export default Breadcrumb;
