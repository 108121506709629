import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { MobileData } from "../NewMobiles/interface";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
interface NewMobileCardGridProps {
  mobile: MobileData;
}

const NewMobileCardList: React.FC<NewMobileCardGridProps> = ({ mobile }) => {
  const [rating, setRating] = useState<any>(0);
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2 ? 3 : result < 3 && result > 2 ? 4 : result;
  };

  const currency = useSelector((state: RootState) => state.country.currency);
  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles);
  const mobileDetail = mobiles.find((m) => m.id === mobile.id); // Filter the specific mobile based on id

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedVariant, setSelectedVariant] = useState(null);
  useEffect(() => {
    const minPrice = Number(searchParams.get("minPrice")) || 0;
    const maxPrice = Number(searchParams.get("maxPrice")) || 1000000;

    const foundVariant = mobileDetail.variants.find((variant: any) => {
      const price = parseFloat(variant.priceInCurrency);
      return price >= minPrice && price <= maxPrice;
    });

    setSelectedVariant(foundVariant);
  }, [searchParams]);

  return (
    <div
      className="card list__card mb-3"
      style={{
        maxHeight: "260px",
        backgroundColor: "#f8f9fa",
        borderRadius: "20px",
        border: "0",
      }}
    >
      <div className="row g-0">
        <div className="col-md-3  d-flex justify-content-center align-items-center">
          <img
            src={mobile.imageUrls[0]}
            className="mt-3 mt-md-0   mx-auto"
            style={{
              mixBlendMode: "multiply",
              width: "97px",
              height: "96px",
              objectFit: "contain",
            }}
            alt="..."
          />
        </div>
        <div className="col-md-8 ">
          <div className="card-body">
            <h6
              style={{ fontSize: "16px", fontWeight: "400" }}
              className="card-title"
            >
              {/* {mobile.brandName} <span>|</span> {mobile.modelName} */}
              {mobile.modelName}
            </h6>
            <h5
              style={{ fontWeight: "500", fontSize: "20px" }}
              className="card-text"
            >
              {/* PKR {mobile.variants[0].priceInPkr} */}
              {currency}{" "}
              {selectedVariant
                ? /* @ts-ignore  */
                  selectedVariant.priceInCurrency
                : mobileDetail.variants[0] &&
                  mobileDetail.variants[0].priceInCurrency}
            </h5>
            <div className="rating d-flex justify-content-start">
              <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                size={18}
                initialValue={getRating(mobile)}
                /* Available Props */
              />
              <span
                // className="ms-2"
                style={{
                  fontSize: "16px",
                  fontWeight: "300",
                  marginRight: "5px",
                  marginTop: "5px",
                }}
              >
                {mobile.mainCameraRating} reviews
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMobileCardList;
