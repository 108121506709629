import { toast } from "react-toastify";

declare global {
  interface Window {
    getRandomId: () => string;
    isEmail: (email: string) => boolean;
    toastify: (msg: string, type?: "success" | "error" | "info" | "warning") => void;
  }
}

export const randomId=window.getRandomId = () => Math.random().toString(36).slice(2);
window.isEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
window.toastify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "info":
      toast.info(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast(msg);
      break;
  }
};
