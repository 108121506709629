// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getDocs, getDocsFromServer, getFirestore, query, where } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcpZGxvekwob_NXDmmmN5D2mf3IOU0Xwg",
  authDomain: "ibad-mobile.firebaseapp.com",
  projectId: "ibad-mobile",
  storageBucket: "ibad-mobile.appspot.com",
  messagingSenderId: "466882013472",
  appId: "1:466882013472:web:0050076f674fc85a0d8f45",
  measurementId: "G-KLK3BQJC6L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const fbProvider = new FacebookAuthProvider()
const googleProvider = new GoogleAuthProvider();

// const getFirebaseData = async (filters: any) => {

//   const mobielRef = collection(db, "Mobiles");

//   // Create a query against the collection.
//   let q = query(collection(db, "Mobiles"));
//   if (filters.brand.length > 0) {
//     q = query(mobielRef, where("brandName", 'in', filters.brand));
//   }

//   const querySnapshot = await getDocs(q);
//   return querySnapshot;
// }

// export { db, analytics, storage, auth, fbProvider, getFirebaseData };
export { db, analytics, storage, auth, fbProvider, googleProvider, signInWithPopup };