import React from 'react'
// import FeaturedMobileCard from './FeaturedMobileCard'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.scss'
import TestimonialCard from './TestimonialCard';
import { Interface } from 'readline';
import file1 from '../../assets/testimonial/File 1.png'
import file2 from '../../assets/testimonial/File 2.png'
import file3 from '../../assets/testimonial/File 3.png'
import file4 from '../../assets/testimonial/File 4.png'
import file5 from '../../assets/testimonial/File 5.png'
import file6 from '../../assets/testimonial/File 6.png'
import file7 from '../../assets/testimonial/File 7.png'




export interface TestimonialPerson {
  id: number;
  image: string;
  name: string;
  place:string;
  review:string
  rating:number;
  date:string

}
const Testimonial: React.FC = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  const testimonial: TestimonialPerson[] = [
    {
      name: "Patrica Doe",
      id: 1,
      date:'20/02/25',
      image: file1,
      place: 'New York, USA',
      review: 'I have been looking for details of an old phone couldnt find anywhere but here love it <3',
      rating: 5
    },
    {
      name: "David Smith",
      id: 2,
      date:'08/11/24',

      image: file2,
      place: 'Munich, GER',
      review: 'Found the Blogs really useful to stay upto date with the mobile phones',
      rating: 4
    },
    {
      name: "Jay Shankar",
      id: 3,
      date:'14/02/25',

      image: file3,
      place: 'Mumbai, IND',
      review: 'I review Phones on youtube and the I always find the info soo accurate, Hats off to the team',
      rating: 5
    },
    {
      name: "Sidhanth Morre",
      id: 4,
      date:'10/12/24',

      image: file4,
      place: 'Dubai, UAE',
      review: `It is really difficult to search for phones on different website thank God for this site.Best solution!`,
      rating: 5
    },
    {
      name: "Michael Wilson",
      id: 5,
      date:'12/12/24',

      image: file5,
      place: 'Houston, TX',
      review: `I can't thank this website enough for helping me understand the complex world of mobile technology.`,
      rating: 4
    },
    {
      name: "Sarah Lee",
      id: 6,
      date:'11/07/24',

      image: file7,
      place: 'Malaga, Spain',
      review: 'The mobile reviews here are on point. I rely on this site to choose my next smartphone.',
      rating: 5
    },
    {
      name: "Phil Dome",
      id: 7,
      date:'10/01/25',
      image: file6,
      place: 'Syndey, Australia',
      review: `This website has saved me time and money. It's a must-visit for mobile tech enthusiasts.`,
      rating: 4
    }
  ];
  return (
    <>
      <div className="testimonial" style={{ margin: "50px auto" }}>
        <div className='container  py-5 ' >
          <div className="row  ">
            <div className='col '>
              <h3 className='header__Top text-white' >What Are The Viewers Saying About Us</h3>
              <p className='header__Bottom text-white'>Helping you navigate your way to the perfect device</p>
            </div>
          </div>
            <Slider {...settings} className='py-2 custom-slider overflow-hidden'>
            <div>
                <TestimonialCard date={testimonial[0].date }name={testimonial[0].name} id ={testimonial[0].id} rating={testimonial[0].rating} review ={testimonial[0].review}place={testimonial[0].place} image ={testimonial[0].image}/>
              </div>
              <div>
                <TestimonialCard date={testimonial[1].date }name={testimonial[1].name} id ={testimonial[1].id} rating={testimonial[1].rating} review ={testimonial[1].review}place={testimonial[1].place} image ={testimonial[1].image}/>
              </div>
              <div>
                <TestimonialCard date={testimonial[2].date }name={testimonial[2].name} id ={testimonial[2].id} rating={testimonial[2].rating} review ={testimonial[2].review}place={testimonial[2].place} image ={testimonial[2].image} />
              </div>
              <div>
                <TestimonialCard date={testimonial[3].date }name={testimonial[3].name} id ={testimonial[3].id} rating={testimonial[3].rating} review ={testimonial[3].review}place={testimonial[3].place} image ={testimonial[3].image} />
              </div>
              <div>
                <TestimonialCard  date={testimonial[4].date }name={testimonial[4].name} id ={testimonial[4].id} rating={testimonial[4].rating} review ={testimonial[4].review}place={testimonial[4].place} image ={testimonial[4].image}/>
              </div>
              <div>
                <TestimonialCard date={testimonial[5].date }name={testimonial[5].name} id ={testimonial[5].id} rating={testimonial[5].rating} review ={testimonial[5].review}place={testimonial[5].place} image ={testimonial[5].image} />
              </div>
              <div>
                <TestimonialCard date={testimonial[6].date } name={testimonial[6].name} id ={testimonial[6].id} rating={testimonial[6].rating} review ={testimonial[6].review}place={testimonial[6].place} image ={testimonial[6].image}/>
              </div>
            </Slider>
            <div className="row mb-5 ">
              <div className="col">
                <p className='text-white ' style={{fontWeight:"500",fontSize:"14px"}}>2.1k+ Reviews</p>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Testimonial;