import React, { useState, useEffect } from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MemoryIcon from "@mui/icons-material/Memory";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import SimCardIcon from "@mui/icons-material/SimCard";
import CloseIcon from "@mui/icons-material/Close";
import "./mobiles.scss";
import { useLocation } from "react-router-dom";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
// import Tooltip from "@mui/material/Tooltip";
// import SearchIcon from "@mui/icons-material/Search";

interface filters {
  brand: string[];
  minPrice: number;
  maxPrice: number;
  recommended: boolean;
  screenSize: string[];
  ram: number[];
  storage: number[];
  sort: string;
}
interface FiltersProps {
  filters: filters;
  applyFilters: Function;
  showFiltersSidebar: boolean;
  setShowFiltersSidebar: any;
  filtersApplied: boolean;
}

const LeftFilters: React.FC<FiltersProps> = ({
  filters,
  applyFilters,
  showFiltersSidebar,
  setShowFiltersSidebar,
  filtersApplied,
}) => {
  // const [showFiltersSidebar, setShowFiltersSidebar] = useState(false);
  const [minPriceRange, setMinPriceRange] = useState(10000);
  const [maxPriceRange, setMaxPriceRange] = useState(600000);
  const [showMoreBrands, setShowMoreBrands] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedBrand = searchParams.get("brand") || "";
  const initialBrands = ["Apple", "Samsung", "Oppo", "Vivo", "Tecno"];
  if (selectedBrand != "" && !initialBrands.includes(selectedBrand)) {
    initialBrands.pop();
    initialBrands.unshift(selectedBrand);
  }

  const [displayedBrands, setDisplayedBrands] =
    useState<string[]>(initialBrands);
  const allBrands = [
    "Apple",
    "Samsung",
    "Oppo",
    "Vivo",
    "Tecno",
    "Infinix",
    "Pixel",
    "RealMe",
    "Nokia",
    "Oneplus",
    "Xiaomi",
    "Nothing",
    "Asus",
    "Itel",
    "Sony",
    "Lenovo",
    "Huawei",
    "Motorola",
  ];
  const cities = [
    "Lahore",
    "Rawalpindi",
    "Faisalabad",
    "Multan",
    "Gujrawala",
    "Sailkot",
    "Wah",
  ];
  const conditions = [
    "Used",
    "New",
    "Open box",
    "Not Working",
    "Refurbished",
  ];

  const handleShowMoreBrandsClick = () => {
    setShowMoreBrands((prevState) => !prevState);
    if (!showMoreBrands) {
      setDisplayedBrands(allBrands);
    } else {
      setDisplayedBrands((displayedBrands) => displayedBrands.slice(0, 5)); // Change here
    }
  };
  const getVals = () => {
    const parent = document.querySelector(".range-slider") as HTMLElement;
    const slides = parent.getElementsByTagName("input");
    const slide1 = parseFloat(slides[0].value);
    const slide2 = parseFloat(slides[1].value);

    if (slide1 > slide2) {
      const tmp = slide2;
      slides[1].value = slide1.toString();
      slides[0].value = tmp.toString();
    }

    const displayElement = parent.querySelector(".rangeValues") as HTMLElement;
    // displayElement.innerHTML = `$ ${slide1}k - $ ${slide2}k`;
  };
  useEffect(() => {
    const sliderSections = document.getElementsByClassName("range-slider");
    for (let x = 0; x < sliderSections.length; x++) {
      const sliders = (sliderSections[x] as HTMLElement).getElementsByTagName(
        "input"
      );
      for (let y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].addEventListener("input", getVals);
          // Manually trigger event first time to display values
          getVals();
        }
      }
    }
  }, []);

  const sortedBrands = [...displayedBrands].sort((a, b) => {
    const aIsSelected = filters.brand.includes(a);
    const bIsSelected = filters.brand.includes(b);

    // If both are selected or not selected, keep original order (return 0)
    // If only a is selected, move a before b (return -1)
    // If only b is selected, move b before a (return 1)
    return aIsSelected === bIsSelected ? 0 : aIsSelected ? -1 : 1;
  });

  return (
    <div
      className={`col col-md-4 col-lg-3 filters_section ${
        showFiltersSidebar ? "show_filters_sidebar" : ""
      }`}
    >
      <div className="filters_section_heading">
        <h5 style={{ fontSize: "16px", fontWeight: "500" }}>
          <SyncAltIcon
            className="me-2"
            style={{ width: "22px", height: "22px" }}
          />{" "}
          FILTERS
        </h5>
      </div>
      <div className={`filters_section_body `}>
        <div className="filters_section_body_heading">
          <h5>
            <SyncAltIcon
              className="me-2"
              style={{ width: "22px", height: "22px" }}
            />{" "}
            FILTERS
          </h5>
          <CloseIcon
            className="filters_sidebar_closeBtn"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => {
              setShowFiltersSidebar(false);
            }}
          />
        </div>
        <hr className="w-50" />
        <h5 style={{ fontSize: "16px", fontWeight: "500" }}>
          <PhoneAndroidIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          City
        </h5>
        {/* City Name Filtering */}
        <div style={{ paddingLeft: "30px" }}>
          {cities.map((brand) => (
            <div className="form-check" key={brand}>
              <input
                className="form-check-input brand-checkbox"
                type="checkbox"
                value={brand}
                id={`brandCheckbox${brand}`}
                // checked={filtersApplied && filters.brand.includes(brand)}
                // onChange={() => applyFilters("brand", brand)}
              />
              <label
                className={`form-check-label ${
                  filtersApplied && filters.brand.includes(brand)
                    ? "active-brand"
                    : ""
                }`}
                htmlFor={`brandCheckbox${brand}`}
              >
                {brand}
              </label>
            </div>
          ))}
        </div>
        <p
          className="text-info ms-5"
          style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
        >
          {/* {showMoreBrands ? "Show Less" : "Show More"} */}
          Show More
        </p>

        {/* ----- */}

        <h5 style={{ fontSize: "16px", fontWeight: "500" }}>
          <PhoneAndroidIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Brand
        </h5>
        {/* Brand Name Filtering */}

        <div style={{ paddingLeft: "30px" }}>
          {sortedBrands.map((brand) => (
            <div className="form-check" key={brand}>
              <input
                className="form-check-input brand-checkbox"
                type="checkbox"
                value={brand}
                id={`brandCheckbox${brand}`}
                checked={filtersApplied && filters.brand.includes(brand)}
                onChange={() => applyFilters("brand", brand)}
              />
              <label
                className={`form-check-label ${
                  filtersApplied && filters.brand.includes(brand)
                    ? "active-brand"
                    : ""
                }`}
                htmlFor={`brandCheckbox${brand}`}
              >
                {brand}
              </label>
            </div>
          ))}
        </div>
        {/* Show more/less option */}
        <p
          className="text-info ms-5"
          style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
          onClick={handleShowMoreBrandsClick}
        >
          {showMoreBrands ? "Show Less" : "Show More"}
        </p>
        {/* Brand Name Filtering */}
        {/* Price Filtering */}
        <h5 style={{ fontSize: "16px", fontWeight: "500" }}>
          <LocalOfferIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Price
        </h5>
        <div
          className="price_filter d-flex"
          style={{
            fontSize: "14px",
            fontWeight: "300",
            marginLeft: "35px",
            marginTop: "15px",
          }}
        >
          <input
            type="number"
            value={filters.minPrice}
            name="minPrice"
            id="minPrice"
            className="form-control"
            placeholder="Min"
            style={{
              margin: "0px 5px",
              padding: "1px 5px",
              height: "30px",
              borderRadius: "7px",
              backgroundColor: "#F8F9FA",
            }}
            onChange={(e) => applyFilters("minPrice", e.target.value)}
          />
          <span>__</span>
          <input
            type="number"
            value={filters.maxPrice}
            name="maxPrice"
            id="maxPrice"
            className="form-control"
            placeholder="Max"
            style={{
              margin: "0px 5px",
              padding: "1px 5px",
              height: "30px",
              borderRadius: "7px",
              backgroundColor: "#F8F9FA",
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);

              if (isNaN(value) || value > 1000000) {
                applyFilters("maxPrice", 1000000);
              } else {
                // If the value is within the allowed range, proceed to apply the filter
                applyFilters("maxPrice", value);
              }
            }}
          />
        </div>
        {/* <div className="range-filter text-center">
                    <input type="range" name="range" id="" className="form-range text-info border-0 mt-4 w-100 px-4" min={10000} max={600000} step={10000} value={maxPriceRange} onChange={(e) => setMaxPriceRange(Number(e.target.value))} />
                </div> */}
        {/* <div className="range-slider">
                    <span className="rangeValues d-none"></span>
                    <input min={500} max={50000}  type="range" />
                    <input  min={50001} max={100000} type="range" />
                </div> */}
        {/* Price Filtering */}
        {/* Primary Camera Filtering */}

        <h5 style={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}>
          <PhoneAndroidIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Condition
        </h5>
        {/* City Name Filtering */}
        <div style={{ paddingLeft: "30px" }}>
          {conditions.map((brand) => (
            <div className="form-check" key={brand}>
              <input
                className="form-check-input brand-checkbox"
                type="checkbox"
                value={brand}
                id={`brandCheckbox${brand}`}
                // checked={filtersApplied && filters.brand.includes(brand)}
                // onChange={() => applyFilters("brand", brand)}
              />
              <label
                className={`form-check-label ${
                  filtersApplied && filters.brand.includes(brand)
                    ? "active-brand"
                    : ""
                }`}
                htmlFor={`brandCheckbox${brand}`}
              >
                {brand}
              </label>
            </div>
          ))}
        </div>

        <h5 style={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}>
          <CameraAltIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Recommended{" "}
        </h5>
        <div
          className="brand_name_filter"
          style={{
            fontSize: "14px",
            fontWeight: "300",
            marginLeft: "35px",
            marginTop: "15px",
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={filters.recommended ? 1 : 0}
              checked={filtersApplied && filters.recommended}
              id="flexCheckDefault1"
              // onChange={() => applyFilters('recommended', true)}
              onChange={() => applyFilters("recommended", !filters.recommended)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault1">
              Recommended
            </label>
          </div>
        </div>
        {/* Primary Camera Filtering */}
        {/* Screen size Filtering */}
        <h5 style={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}>
          <CloseFullscreenRoundedIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />{" "}
          Screen Size
        </h5>
        <div
          className="brand_name_filter"
          style={{
            fontSize: "14px",
            fontWeight: "300",
            marginLeft: "35px",
            marginTop: "20px",
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="4.0_to_below"
              id="screenSizeFilter4Below"
              checked={filters?.screenSize?.includes("4.0_to_below")}
              onChange={() => applyFilters("screenSize", "4.0_to_below")}
            />
            <label
              className="form-check-label"
              htmlFor="screenSizeFilter4Below"
            >
              4 inch and below
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="4.0_to_4.5"
              id="screenSizeFilter4To4.5"
              checked={filters?.screenSize?.includes("4.0_to_4.5")}
              onChange={() => applyFilters("screenSize", "4.0_to_4.5")}
            />
            <label
              className="form-check-label"
              htmlFor="screenSizeFilter4To4.5"
            >
              4.0 inch to 4.5 inch
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="5.0_to_5.5"
              id="screenSizeFilter5To5.5"
              checked={filters?.screenSize?.includes("5.0_to_5.5")}
              onChange={() => applyFilters("screenSize", "5.0_to_5.5")}
            />
            <label
              className="form-check-label"
              htmlFor="screenSizeFilter5To5.5"
            >
              5.0 inch to 5.5 inch
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="5.6_to_6.9"
              id="screenSizeFilter5.6To6.9"
              checked={filters?.screenSize?.includes("5.6_to_6.9")}
              onChange={() => applyFilters("screenSize", "5.6_to_6.9")}
            />
            <label
              className="form-check-label"
              htmlFor="screenSizeFilter5.5To6.9"
            >
              5.6 inch to 6.9 inch
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="7.0_to_above"
              id="screenSizeFilter7.0ToAbove"
              checked={filters?.screenSize?.includes("7.0_to_above")}
              onChange={() => applyFilters("screenSize", "7.0_to_above")}
            />
            <label
              className="form-check-label"
              htmlFor="screenSizeFilter7.0ToAbove"
            >
              7.0 to Above
            </label>
          </div>
        </div>
        {/* Screen size Filtering */}
        {/* Ram Filtering */}
        <h5 style={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}>
          <SimCardIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Ram
        </h5>
        <div
          className="brand_name_filter"
          style={{
            fontSize: "14px",
            fontWeight: "300",
            marginLeft: "35px",
            marginTop: "15px",
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={2}
              id="ramFilter1GB"
              checked={filtersApplied && filters.ram.includes(2)}
              onChange={() => applyFilters("ram", 2)}
            />
            <label className="form-check-label" htmlFor="ramFilter1GB">
              2 GB or Less
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={3}
              id="ramFilter2GB"
              checked={filtersApplied && filters.ram.includes(3)}
              onChange={() => applyFilters("ram", 3)}
            />
            <label className="form-check-label" htmlFor="ramFilter2GB">
              3 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={4}
              id="flexCheckDefault3"
              checked={filtersApplied && filters.ram.includes(4)}
              onChange={() => applyFilters("ram", 4)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault3">
              4 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={6}
              id="flexCheckDefault4"
              checked={filtersApplied && filters.ram.includes(6)}
              onChange={() => applyFilters("ram", 6)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault4">
              6 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={8}
              id="ramFilter6GB"
              checked={filtersApplied && filters.ram.includes(8)}
              onChange={() => applyFilters("ram", 8)}
            />
            <label className="form-check-label" htmlFor="ramFilter6GB">
              8 GB
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={12}
              id="ramFilter12GB"
              checked={filtersApplied && filters.ram.includes(12)}
              onChange={() => applyFilters("ram", 12)}
            />
            <label className="form-check-label" htmlFor="ramFilter12GB">
              12 GB or Above
            </label>
          </div>
        </div>
        {/* Ram Filtering */}
        {/* Memory Filtering */}
        <h5 style={{ fontSize: "16px", fontWeight: "500", marginTop: "15px" }}>
          <MemoryIcon
            className=" me-3"
            style={{ width: "18px", height: "18px" }}
          />
          Memory
        </h5>
        <div
          className="brand_name_filter"
          style={{
            fontSize: "14px",
            fontWeight: "300",
            marginLeft: "35px",
            marginTop: "15px",
          }}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={4}
              id="storageFilter4GB"
              checked={filtersApplied && filters.storage.includes(4)}
              onChange={() => applyFilters("storage", 4)}
            />
            <label className="form-check-label" htmlFor="storageFilter4GB">
              4 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={8}
              id="storageFilter8GB"
              checked={filtersApplied && filters.storage.includes(8)}
              onChange={() => applyFilters("storage", 8)}
            />
            <label className="form-check-label" htmlFor="storageFilter8GB">
              8 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={16}
              id="storageFilter16GB"
              checked={filtersApplied && filters.storage.includes(16)}
              onChange={() => applyFilters("storage", 16)}
            />
            <label className="form-check-label" htmlFor="storageFilter16GB">
              16 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={32}
              id="storageFilter32GB"
              checked={filtersApplied && filters.storage.includes(32)}
              onChange={() => applyFilters("storage", 32)}
            />
            <label className="form-check-label" htmlFor="storageFilter32GB">
              32 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={64}
              id="storageFilter64GB"
              checked={filtersApplied && filters.storage.includes(64)}
              onChange={() => applyFilters("storage", 64)}
            />
            <label className="form-check-label" htmlFor="storageFilter64GB">
              64 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={128}
              id="storageFilter128GB"
              checked={filtersApplied && filters.storage.includes(128)}
              onChange={() => applyFilters("storage", 128)}
            />
            <label className="form-check-label" htmlFor="storageFilter128GB">
              128 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={256}
              id="storageFilter256GB"
              checked={filtersApplied && filters.storage.includes(256)}
              onChange={() => applyFilters("storage", 256)}
            />
            <label className="form-check-label" htmlFor="storageFilter256GB">
              256 GB
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={512}
              id="storageFilter512GB"
              checked={filtersApplied && filters.storage.includes(512)}
              onChange={() => applyFilters("storage", 512)}
            />
            <label className="form-check-label" htmlFor="storageFilter512GB">
              512 GB
            </label>
          </div>
          {/* Memory Filtering */}
        </div>
      </div>
    </div>
  );
};

export default LeftFilters;
