import { Helmet } from "react-helmet";
import Breadcrumb from "./Breadcrumb";
import TopBanner from "./TopBanner";
import "./style.scss"

const Screen1 = () => {
  return (
    <div className="container my-5 blogs-parent-div screen1">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Breadcrumb />
      <TopBanner />
    </div>
  );
};

export default Screen1;
