import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { StateProps, scrollToTop } from "./ParentStates";
import { RootState } from "../../../../store/store";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { MobileData } from "../../NewMobiles/interface";

const State2 = ({
  activeState,
  setActiveState,
  userAdData,
  setUserAdData,
}: StateProps) => {
  const mobiles: MobileData[] = useSelector(
    (state: RootState) => state.mobiles.mobiles
  );
  const [selectedBrand, setSelectedBrand] = useState(userAdData.brand || "");
  const [selectedModel, setSelectedModel] = useState(userAdData.model || "");
  const [newMobileId, setNewMobileId] = useState(userAdData.newMobileId || "");
  const [selectedConditions, setSelectedConditions] = useState<string[]>(
    userAdData.condition || []
  );
  const [selectedCategory, setSelectedCategory] = useState(
    userAdData.category || ""
  );
  const [selectedVariant, setSelectedVariant] = useState("");
  const [variants, setVariants] = useState<
    { ram: number; internalStorage: number }[]
  >([]);
  const [price, setPrice] = useState(userAdData.price || "");
  const [error, setError] = useState("");
  const [models, setModels] = useState<string[]>([]);
  const [brandInput, setBrandInput] = useState("");
  const [modelInput, setModelInput] = useState("");
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [showVariantDropdown, setShowVariantDropdown] = useState(false);

  const brandInputRef = useRef<HTMLInputElement>(null);
  const modelInputRef = useRef<HTMLInputElement>(null);
  const variantInputRef = useRef<HTMLInputElement>(null);
  const brandDropdownRef = useRef<HTMLDivElement>(null);
  const modelDropdownRef = useRef<HTMLDivElement>(null);
  const variantDropdownRef = useRef<HTMLDivElement>(null);
  const [brandDropdownWidth, setBrandDropdownWidth] = useState("0px");
  const [modelDropdownWidth, setModelDropdownWidth] = useState("0px");
  const [variantDropdownWidth, setVariantDropdownWidth] = useState("0px");

  useEffect(() => {
    if (brandInputRef.current) {
      setBrandDropdownWidth(`${brandInputRef.current.offsetWidth}px`);
    }
    if (modelInputRef.current) {
      setModelDropdownWidth(`${modelInputRef.current.offsetWidth}px`);
    }
    if (variantInputRef.current) {
      setVariantDropdownWidth(`${variantInputRef.current.offsetWidth}px`);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        brandInputRef.current &&
        !brandInputRef.current.contains(event.target as Node) &&
        brandDropdownRef.current &&
        !brandDropdownRef.current.contains(event.target as Node)
      ) {
        setShowBrandDropdown(false);
      }

      if (
        modelInputRef.current &&
        !modelInputRef.current.contains(event.target as Node) &&
        modelDropdownRef.current &&
        !modelDropdownRef.current.contains(event.target as Node)
      ) {
        setShowModelDropdown(false);
      }

      if (
        variantDropdownRef.current &&
        !variantDropdownRef.current.contains(event.target as Node)
      ) {
        setShowVariantDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get unique brands
  const uniqueBrands = Array.from(
    new Set(mobiles.map((mobile) => mobile.brandName))
  );

  // Handle brand input change
  const handleBrandInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setBrandInput(value);
    setShowBrandDropdown(true);

    // Reset selected brand and model if brand input changes
    setSelectedBrand("");
    setSelectedModel("");
    setModelInput("");
    setVariants([]);
    setSelectedVariant("");
    setModels([]);
  };

  const handleBrandSelect = (brand: string) => {
    setSelectedBrand(brand);
    setBrandInput(brand);
    setShowBrandDropdown(false);

    // Get models for the selected brand
    const brandModels = mobiles
      .filter((mobile) => mobile.brandName === brand)
      .map((mobile) => mobile.modelName);

    setModels(brandModels);
    setSelectedModel(""); // Reset model selection when brand changes
    setModelInput(""); // Clear model input when brand changes
  };

  // Handle model input change
  const handleModelInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setModelInput(value);
    setShowModelDropdown(true);
  };

  const handleModelSelect = (model: string) => {
    setSelectedModel(model);
    setModelInput(model);
    setShowModelDropdown(false);

    const selectedMobile = mobiles.find((mobile) => mobile.modelName === model);

    const modelVariants = selectedMobile?.variants || [];
    setVariants(modelVariants);
    setSelectedVariant(""); // Reset variant selection when model changes

    setNewMobileId(selectedMobile?.id || "");
  };

  const handleVariantSelect = (variant: string) => {
    setSelectedVariant(variant);
    setShowVariantDropdown(false);
  };

  // Handle condition selection
  const handleConditionClick = (condition: string) => {
    setSelectedConditions((prevSelectedConditions) => {
      if (prevSelectedConditions.includes(condition)) {
        return prevSelectedConditions.filter((c) => c !== condition);
      } else {
        return [...prevSelectedConditions, condition];
      }
    });
  };

  // Handle category selection
  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const handleContinue = () => {
    if (
      !selectedBrand ||
      !selectedModel ||
      selectedConditions.length === 0 ||
      !price ||
      !selectedCategory ||
      !selectedVariant
    ) {
      setError("All fields are not selected");
      return;
    }

    setError("");
    setUserAdData((prevData) => ({
      ...prevData,
      brand: selectedBrand,
      model: selectedModel,
      condition: selectedConditions,
      category: selectedCategory,
      variant: selectedVariant,
      newMobileId: newMobileId,
      price: price,
    }));
    setActiveState(3);
    scrollToTop();
  };

  return (
    <>
      <div className="row parent-top-banner-state2 m-2 m-md-0">
        <div className="col-12">
          <h5 className="head-title">Add Brand / Model</h5>
        </div>

        {error && <div className="col-12 text-danger">{error}</div>}

        <div className="col-12 col-md-6">
          <label htmlFor="brand-input">Brand</label>
          <input
            ref={brandInputRef}
            id="brand-input"
            type="text"
            value={brandInput}
            onChange={handleBrandInputChange}
            onFocus={() => setShowBrandDropdown(true)}
            placeholder="Type or select brand"
          />
          {showBrandDropdown && (
            <div
              ref={brandDropdownRef}
              className="dropdown"
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                width: brandDropdownWidth,
              }}
            >
              {uniqueBrands
                .filter((brand) =>
                  brand.toLowerCase().includes(brandInput.toLowerCase())
                )
                .map((brand) => (
                  <div
                    key={brand}
                    className="dropdown-item"
                    onClick={() => handleBrandSelect(brand)}
                  >
                    {brand}
                  </div>
                ))}
            </div>
          )}

          <label htmlFor="mob-condition">Condition</label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {["New", "Used", "Open Box", "Refurbished", "Not Working"].map(
              (condition) => (
                <button
                  key={condition}
                  className={`btn condition-button ${
                    selectedConditions.includes(condition)
                      ? "btn-outline-info"
                      : "btn-light bg-white"
                  }`}
                  onClick={() => handleConditionClick(condition)}
                >
                  {condition}
                </button>
              )
            )}
          </div>

          <label htmlFor="category" style={{ marginTop: "20px" }}>
            Category
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {["Sim Locked", "PTA", "Non PTA"].map((category) => (
              <button
                key={category}
                className={`btn category-button ${
                  selectedCategory === category
                    ? "btn-outline-info"
                    : "btn-light bg-white"
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label htmlFor="model-input">Model</label>
          <input
            ref={modelInputRef}
            id="model-input"
            type="text"
            value={modelInput}
            onChange={handleModelInputChange}
            onFocus={() => setShowModelDropdown(true)}
            placeholder="Type or select model"
            disabled={!selectedBrand}
          />
          {showModelDropdown && (
            <div
              ref={modelDropdownRef}
              className="dropdown"
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                width: modelDropdownWidth,
              }}
            >
              {models
                .filter((model) =>
                  model.toLowerCase().includes(modelInput.toLowerCase())
                )
                .map((model) => (
                  <div
                    key={model}
                    className="dropdown-item"
                    onClick={() => handleModelSelect(model)}
                  >
                    {model}
                  </div>
                ))}
            </div>
          )}

          <label htmlFor="mob-price">Set Price</label>
          <div className="input-container">
            <span className="fixed-text">Rs |</span>
            <input
              type="number"
              placeholder=""
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <label htmlFor="variant-input">Variant</label>
          <input
            ref={variantInputRef}
            id="variant-input"
            type="text"
            value={selectedVariant}
            onChange={(e) => setSelectedVariant(e.target.value)}
            onFocus={() => setShowVariantDropdown(true)}
            placeholder="Type or select variant"
            disabled={!selectedModel}
          />
          {showVariantDropdown && (
            <div
              ref={variantDropdownRef}
              className="dropdown"
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                width: variantDropdownWidth,
              }}
            >
              {variants
                .filter((variant) =>
                  `${variant.ram}/${variant.internalStorage} GB`
                    .toLowerCase()
                    .includes(selectedVariant.toLowerCase())
                )
                .map((variant, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() =>
                      handleVariantSelect(
                        `${variant.ram}/${variant.internalStorage} GB`
                      )
                    }
                  >
                    {variant.ram}/{variant.internalStorage} GB
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <p className="text-info">
            <LightbulbIcon />
          </p>
          <p>
            Post your ad on our platform and effortlessly edit or update it
            anytime
          </p>
        </div>
        <div className="button-parent">
          <button
            className="btn btn-info"
            onClick={handleContinue}
            style={{ marginLeft: "10px" }}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default State2;
