import { Link } from "react-router-dom";

import { BlogDataInterface, formatDateFromTimestamp } from "./utils";
import BlogCard from "./BlogCardLatest";
import { useEffect, useState } from "react";

interface BlogsTopSectionProps {
  blogsData: BlogDataInterface[];
}

const BlogsTopSection: React.FC<BlogsTopSectionProps> = ({ blogsData }) => {
  
  let latestBlogs = blogsData
    .sort((a, b) => {
      return b.date._seconds - a.date._seconds;
    });

  // latestBlogs = [latestBlogs[0], latestBlogs[0], latestBlogs[0]];

  const [activeBlogs, setActiveBlogs] = useState(latestBlogs.slice(0, 3)); // Start with the first three blogs

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBlogs((current) => {
        // Calculate next index considering the circular nature of the carousel
        const nextIndex = latestBlogs.indexOf(current[current.length - 1]) + 1;

        // Compute the new set of active blogs by wrapping around if necessary
        const newActiveBlogs = [];
        for (let i = 0; i < 3; i++) {
          newActiveBlogs.push(
            latestBlogs[(nextIndex + i) % latestBlogs.length]
          );
        }

        return newActiveBlogs;
      });
    }, 5000); // Change blogs every 5 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [latestBlogs]); // Effect dependencies

  useEffect(() => {
//    console.log("activeBlogs");
//console.log(activeBlogs);
  }, [activeBlogs]);

  if (activeBlogs.length == 0) {
    return (
      <div className="container mt-5 mb-3 ">
        <p className="text-center">No latest blogs found.</p>
      </div>
    );
  }

  return (
    <div className="container mt-5 blogs-top-section">
      <div className="row g-3">
        <div className="col-md-6 top-card-div">
          <BlogCard
            blog={activeBlogs[0]}
            firstItem={true}
            hotTopic={activeBlogs[0].id == latestBlogs[0].id ? true : false}
          />
        </div>
        <div
          className="col-md-6 top-card-div top-card-div2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            rowGap: "2%",
          }}
        >
          {activeBlogs[1] && <BlogCard blog={activeBlogs[1]} />}
          {activeBlogs[2] && <BlogCard blog={activeBlogs[2]} />}
        </div>
      </div>
    </div>
  );
};

export default BlogsTopSection;
