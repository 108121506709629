import { useState } from "react";
import { StateProps, scrollToTop } from "./ParentStates";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const State1 = ({
  activeState,
  setActiveState,
  userAdData,
  setUserAdData,
}: StateProps) => {
  const [title, setTitle] = useState(userAdData.title);
  const [description, setDescription] = useState(userAdData.description);
  const [hasTitleError, setHasTitleError] = useState(false);
  const [hasDescriptionError, setHasDescriptionError] = useState(false);

  const handleContinue = () => {
    const isTitleValid = title.length >= 10;
    const isDescriptionValid = description.length >= 10;

    if (isTitleValid && isDescriptionValid) {
      setHasTitleError(false);
      setHasDescriptionError(false);
      setUserAdData((prevData) => ({
        ...prevData,
        title,
        description,
      }));
      setActiveState(2);
      scrollToTop();

      // console.log({
      //   ...userAdData,
      //   title,
      //   description,
      // });
    } else {
      setHasTitleError(!isTitleValid);
      setHasDescriptionError(!isDescriptionValid);
    }
  };

  const handleTitleChange = (e: any) => {
    const value = e.target.value;
    setTitle(value);

    if (value.length >= 10) {
      setHasTitleError(false);
    }
  };

  const handleDescriptionChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);

    if (value.length >= 10) {
      setHasDescriptionError(false);
    }
  };

  return (
    <>
      <div className="row parent-top-banner-state1 m-2 m-md-0">
        <h5 className="head-title">Add Your Mobile Details</h5>

        <label
          htmlFor="ad-title"
          className={`title-label ${hasTitleError ? "error-field" : ""}`}
        >
          Ad Title
        </label>
        <input
          id="ad-title"
          type="text"
          className={`title-input ${hasTitleError ? "error-field" : ""}`}
          placeholder="Mention the key features of your item (e.g. brand, model, Variant, type, Ram, Memory, Screensize)"
          value={title}
          onChange={handleTitleChange}
        />
        <p className={`title-p ${hasTitleError ? "error-field" : ""}`}>
          Title should contain at least 10 alphanumeric characters. Please edit
          the field
        </p>

        <label
          htmlFor="ad-description"
          className={`desc-label ${hasDescriptionError ? "error-field" : ""}`}
        >
          Ad Description
        </label>
        <textarea
          id="ad-description"
          name="ad-description"
          rows={5}
          placeholder="Include condition, features and reason for selling"
          className={`desc-textarea ${
            hasDescriptionError ? "error-field" : ""
          }`}
          value={description}
          onChange={handleDescriptionChange}
        ></textarea>
        <p className={`desc-p ${hasDescriptionError ? "error-field" : ""}`}>
          Description should contain at least 10 alphanumeric characters. Please
          edit the field
        </p>
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <p className="text-info">
            <LightbulbIcon />
          </p>
          <p>
            Post your ad on our platform and effortlessly edit or update it
            anytime
          </p>
        </div>
        <button
          className="btn btn-info"
          style={{ fontWeight: 400 }}
          onClick={handleContinue}
        >
          CONTINUE
        </button>
      </div>
    </>
  );
};

export default State1;
