import React from "react";
import { Helmet } from "react-helmet";
import TermsAndConditions from "./TermsAndConditions";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Ibad Mobiles</title>
        <meta
          name="description"
          content="Review the terms and conditions for using the Ibad Mobile website."
        />
        <meta
          name="keywords"
          content="terms and conditions, user agreement, ibad mobile terms, legal, user terms"
        />
      </Helmet>
      <TermsAndConditions/>
    </>
  );
};

export default Index;
