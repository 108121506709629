import React from "react";
import { MobileData } from "./interface2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface NewMobileCardGridProps {
  mobile: MobileData; // Define the prop to accept mobile data
}
const FeaturedMobileCard: React.FC<NewMobileCardGridProps> = ({ mobile }) => {
  const naviagte = useNavigate();

  const currency = useSelector((state: RootState) => state.country.currency);
  return (
    <div
      className="container"
      onClick={() => {
        naviagte(`/mobile-details/${mobile.id}/${mobile.modelName}`);
      }}
    >
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 mb-4 ">
          <div
            className="card hover-div"
            style={{
              backgroundColor: "#F8F9FA",
              margin: "auto",
              borderRadius: "20px",
              border: "0",
              width: "221px",
              minHeight: "254px",
              cursor: "pointer",
            }}
          >
            <div className="mt-md-1  d-flex justify-content-center align-items-center  position-relative">
              <div
                style={{
                  padding: "2px 0px",
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: "400",
                  position: "absolute",
                  top: "10px",
                  left: "20px",
                  zIndex: "999",
                  backgroundColor: "#d80032",
                  width: "89px",
                  height: "21px",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                FEATURED
              </div>
              <img
                src={mobile.imageUrls[0]}
                style={{
                  mixBlendMode: "multiply",
                  objectFit: "contain",
                  paddingTop: "10px",
                }}
                width="124px"
                height="160px"
                alt="photo"
              />
            </div>
            <div className="card-body" style={{ height: "100px" }}>
              <h6 style={{ fontSize: "16px", fontWeight: "400" }}>
                {mobile.modelName}
              </h6>
              <h5 style={{ fontWeight: "500", fontSize: "20px" }} className="">
                {currency} {mobile.variants[0]?.priceInCurrency}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeaturedMobileCard;
