import React, { useContext, useState, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, fbProvider } from "../../../config/firebase";
// import { AuthContext } from '../../../context/AuthContext';

interface LoginState {
  email: string;
  password: string;
}

const initialState: LoginState = { email: "", password: "" };

const Login: React.FC = () => {
  // const { dispatch }:any = useContext(AuthContext);
  const [state, setState] = useState<LoginState>(initialState);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isFbLogin, setIsFbLogin] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { email, password } = state;

    setIsProcessing(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userToken = await user.getIdToken();
        // dispatch({ type: 'LOGIN', payload: { user } });
        navigate("/");
        window.toastify("User is sign in successfully", "success");
        setIsProcessing(false);
      })
      .catch((err) => {
        window.toastify("Something got error while login", "error");
        setIsProcessing(false);
      });
  };
  const handleGoogleSignIn = async () => {
    setIsGoogleLogin(true);
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // dispatch({ type: 'LOGIN' });
      // console.log('user is signed up with Google');
      window.toastify("User is signed up successfully", "success");
      setIsGoogleLogin(false);
    } catch (err) {
      console.error(err);
      window.toastify("Something went wrong while signing up", "error");
      setIsGoogleLogin(false);
    }
  };
  const handleFacebookSignIn = () => {
    setIsFbLogin(true);
    signInWithPopup(auth, fbProvider)
      .then((result) => {
        const user = result.user;
        // dispatch({ type: 'LOGIN', payload: user })
        window.toastify("facebook registered sign in successfully", "success");
        navigate("/");
        const credential = FacebookAuthProvider.credentialFromResult(result);
        setIsFbLogin(false);
      })
      .catch((err) => {
        console.error(err);
        window.toastify("Something went wrong", "error");
        setIsFbLogin(false);
      });
  };

  return (
    <>
      <div
        className="container-fluid pt-3"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div className="container">
          <div className="row py-5 px-2  ">
            <div className="col-12 col-lg-6 loginBack shadow-sm d-flex flex-column justify-content-center align-items-center text-white "></div>
            <div className="col-12 col-lg-6 bg-light shadow-sm">
              <div className="row pt-5 px-2 px-md-5 ">
                <div className="col-12 text-center ">
                  <h1 className="" style={{ fontFamily: "fantasy" }}>
                    Log in
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row py-5">
                    <div className="col-12">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control  py-2"
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control  py-2 mt-4"
                        name="password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 mt-3 text-center ">
                      {/* <Link className='text-dark'>Forgot Password?</Link> */}
                    </div>
                    <div className="col-12 mt-4 text-center ">
                      <button
                        className=" zoom-button  py-md-3 px-5 bg-danger fw-semibold  "
                        disabled={isProcessing}
                        style={{ boxShadow: "0 0 25px rgb(255, 168, 168)" }}
                      >
                        {!isProcessing ? (
                          <span>LOGIN</span>
                        ) : (
                          <div className="spinner spinner-grow spinner-grow-sm"></div>
                        )}
                      </button>
                    </div>
                    <div className="col-5 mt-4">
                      <hr />
                    </div>
                    <div className="col-2 text-center text-secondary mt-4">
                      <p>OR</p>
                    </div>
                    <div className="col-5 mt-4">
                      <hr />
                    </div>
                    <div className="col-12">
                      <button
                        className="  btn w-100 rounded-1 py-2 facebook d-inline-block my-2 fw-semibold "
                        disabled={isFbLogin}
                        onClick={handleFacebookSignIn}
                      >
                        {!isFbLogin ? (
                          "Continue With Facebook"
                        ) : (
                          <div className="spinner-border spinner-border-sm"></div>
                        )}
                      </button>
                    </div>
                    <div className="col-12">
                      <button
                        className="  btn w-100 rounded-1  d-inline-block google py-2  fw-semibold  "
                        disabled={isGoogleLogin}
                        onClick={handleGoogleSignIn}
                      >
                        {!isGoogleLogin ? (
                          "Continue With Google"
                        ) : (
                          <div className="spinner-border spinner-border-sm"></div>
                        )}
                      </button>
                    </div>

                    <p className="text-center text-secondary pt-3">
                      Don't Have An Account?
                      <Link to="/auth/register" className="text-dark">
                        Create One
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
