import "./videos.scss";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateIcon from "../../../assets/dateIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "antd";
import { useState } from "react";
// import { videosData } from "./VideosData";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Video {
  id: string;
  title: string;
  category: string;
  date: string;
  thumbnail: string;
  videoUrl: string;
}

const ResponsiveGrid = ({
  videosData,
  isLoading,
}: {
  videosData: Video[];
  isLoading: boolean;
}) => {
  // State to keep track of the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to change page
  const handleChangePage = (page: any) => {
    setCurrentPage(page);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };

  const ITEMS_PER_PAGE = 8;
  // Calculate the index of the last and first video item
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  // Current videos to display
  const currentVideos = videosData.slice(indexOfFirstItem, indexOfLastItem);

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === "page") {
      return (
        <button
          style={{
            backgroundColor: currentPage === current ? "#9D4EDD" : "#F8F9FA",
            color: currentPage === current ? "#FFFFFF" : "#000000",
            borderRadius: "8px",
            padding: "0px 12px",
            border: "0.5px solid #E1E4E8",
          }}
        >
          {current}
        </button>
      );
    }
    if (type === "prev" || type === "next") {
      return (
        <button
          style={{
            backgroundColor: "#F8F9FA",
            color: "#000000",
            borderRadius: "5px",
            padding: "0px 6px",
            border: "0.5px solid #E1E4E8",
          }}
        >
          {type === "prev" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </button>
      );
    }
    return originalElement;
  };

  // State for managing the video player visibility and URL
  const [isPlayerOpen, setPlayerOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  // Function to open the video player
  const openVideoPlayer = (videoUrl: string) => {
    setCurrentVideoUrl(videoUrl);
    setPlayerOpen(true);
  };

  // Function to close the video player
  const closeVideoPlayer = () => {
    setPlayerOpen(false);
    setCurrentVideoUrl("");
  };

  if (isLoading === true) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
  if (videosData.length === 0) {
    return (
      <div className="no-videos-found">
        <p>No videos found.</p>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        {currentVideos.map((video, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-3 video-card p-3">
            <div className="video-thumbnail">
              <img
                src={video.thumbnail}
                alt={video.title}
                className="img-fluid"
              />
              <button
                className="btn play-button"
                onClick={() => openVideoPlayer(video.videoUrl)}
              >
                {/* Insert play icon here */}
              </button>
            </div>
            <div className="video-info">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="date"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <img
                    src={DateIcon}
                    alt=""
                    style={{
                      width: "12px",
                      objectFit: "contain",
                      marginRight: "6px",
                    }}
                  />
                  <p className="mb-1">{video.date}</p>
                </div>
                <div className="category">
                  {video.category.charAt(0).toUpperCase() +
                    video.category.slice(1)}
                </div>
              </div>
              <div className="title">{video.title.substring(0, 50)}...</div>
            </div>
          </div>
        ))}
      </div>

      {/* Video Player Overlay */}
      {isPlayerOpen && (
        <div className="video-overlay" onClick={closeVideoPlayer}>
          <div className="video-container" onClick={(e) => e.stopPropagation()}>
            <iframe
              width="560"
              height="315"
              src={`${currentVideoUrl}?autoplay=1`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="Video Player"
            ></iframe>
            <button className="video-close-btn" onClick={closeVideoPlayer}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
      <div className="row mb-5 mt-2">
        <div className="col d-flex justify-content-end">
          <Pagination
            current={currentPage}
            onChange={handleChangePage}
            total={videosData.length}
            pageSize={ITEMS_PER_PAGE}
            showSizeChanger={false}
            itemRender={itemRender}
          />
        </div>
      </div>
    </>
  );
};

export default ResponsiveGrid;
