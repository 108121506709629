import PostAdImg from "../../../../assets/postad-img.png";
import Check from "../../../../assets/Check.png";
import Img1 from "../../../../assets/used-mobile-1.png";
import Img2 from "../../../../assets/used-mobile-2.png";
import Img3 from "../../../../assets/used-mobile-3.png";

const TopBanner = () => {
  return (
    <>
      <div className="row parent-top-banner m-2 m-md-0">
        <div className="col-12 col-md-6 left-col">
          <img src={PostAdImg} alt="Post Ad" className="ad_img" />
          <h6 className="pb-5">Post your Ad on Ibad Mobile</h6>
        </div>
        <div className="col-12 col-md-6 right-col pt-0 pb-4 py-md-4 px-4 ps-md-0 pe-md-5">
          <p style={{ paddingBottom: "10px" }}>
            Effortlessly post your ad from any device and List your Ad for Free
            in just few Simple Steps.
          </p>
          <div>
            <div className="custom-list-item">
              <img src={Check} alt="check" />
              <p>Receive Authentic offers from Verified Buyers.</p>
            </div>
            <div className="custom-list-item">
              <img src={Check} alt="check" />
              <p>Sell your phone Quickly and at the Highest Price.</p>
            </div>
            <div className="custom-list-item">
              <img src={Check} alt="check" />
              <p>
                Sell it online hassle-free and get paid fast. With just a few
                clicks, turn your device into cash today!
              </p>
            </div>
          </div>
          <div className="btn-row">
            <button className="btn btn-info" style={{ minWidth: "120px" }}>
              POST AN AD
            </button>
            <p className="text-muted m-0">
              By continuing, you are agreeing to the terms of service and
              privacy policy.
            </p>
          </div>
        </div>
      </div>
      <div className="row parent-bottom-banner">
        <h4
          className="text-center mt-5 mb-5"
          style={{
            fontFamily: "Roboto",
            fontWeight: "800",
            fontSize: "22px",
          }}
        >
          3 Simple Steps To Sell Your Mobilephone
        </h4>
        <div className="row gx-2 gx-md-5">
          <div className="col-12 col-md-4 custom-card-ad">
            <img src={Img1} alt="check" />
            <h6>Sign Up</h6>
            <p>Register yourself on ibadmobile.com to post an ad.</p>
          </div>
          <div className="col-12 col-md-4 custom-card-ad">
            <img src={Img2} alt="check" />
            <h6>Create your Ad</h6>
            <p>
              Provide necessary details about your used phone and make sure to
              upload clear photos.
            </p>
          </div>
          <div className="col-12 col-md-4 custom-card-ad">
            <img src={Img3} alt="check" />
            <h6>Get Instant Offers</h6>
            <p>Relax & get ready for instant offers from genuine buyers.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
