import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchAllMobiles, setMobileData } from "./store/slice/mobilesSlice";
import { fetchAllUsedMobiles } from "./store/slice/usedMobilesSlice";
import { AppDispatch, RootState } from "./store/store";
import axios from "axios";
import { MobileData } from "./Component/FeaturedMobile/interface2";
import { setCountryAndCurrency } from "./store/slice/countrySlice";
import { useSelector } from "react-redux";
import ScreenLoader from "./Component/ScreenLoader/ScreenLoader";

export function addpriceInCurrency(mobiles: MobileData[], currency: string) {
  return mobiles.map((mobile) => {
    return {
      ...mobile,
      variants: mobile.variants.map((variant) => {
        let priceInCurrency;
        switch (currency) {
          case "RS":
            priceInCurrency = variant.priceInPkr;
            break;
          case "$":
            priceInCurrency = variant.priceInUsd;
            break;
          case "AED":
            priceInCurrency = variant.priceInAed;
            break;
          case "€":
            priceInCurrency = variant.priceInEur;
            break;
          case "₹":
            priceInCurrency = variant.priceInInr;
            break;
          case "£":
            priceInCurrency = variant.priceInGBP;
            break;
          case "AUD":
            priceInCurrency = variant.priceInAUD;
            break;
          case "CAD":
            priceInCurrency = variant.priceInCAD;
            break;
          default:
            priceInCurrency = variant.priceInUsd;
            break;
        }
        return {
          ...variant,
          priceInCurrency: priceInCurrency,
        };
      }),
    };
  });
}

const DataLoader = () => {
  const dispatch: AppDispatch = useDispatch();
  const isDispatched = useRef(false);
  const [isLoading, setIsLoading] = useState(true); // Local isLoading state
  const status = useSelector((state: RootState) => state.mobiles.status);

  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles);

  useEffect(() => {
    setIsLoading(status === "loading");
  }, [status]);

  useEffect(() => {
    // Attempt to retrieve saved country and currency from localStorage
    const savedCountry = localStorage.getItem("country");
    const savedCurrency = localStorage.getItem("currency");

    const fetchIpInfo = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=bfdc532160166a"
        );
        const country = response.data.country;
        const currencyMapping: { [key: string]: string } = {
          IN: "₹",
          PK: "RS",
          US: "$",
          UAE: "AED",
          DE: "€",
          GB: "£",
          AU: "AUD",
          CA: "CAD",
        };

        const currency =
          currencyMapping[country as keyof typeof currencyMapping] || "$";

        // If the country code is not found in the currencyMapping, default to US settings
        if (!currencyMapping[country as keyof typeof currencyMapping]) {
          localStorage.setItem("country", "US");
          localStorage.setItem("currency", "$");
          dispatch(setCountryAndCurrency({ country: "US", currency: "$" }));
        } else {
          localStorage.setItem("country", country);
          localStorage.setItem("currency", currency);
          dispatch(setCountryAndCurrency({ country, currency }));
        }

        if (!isDispatched.current) {
          await dispatch(fetchAllMobiles());
          await dispatch(fetchAllUsedMobiles());
          isDispatched.current = true;
          setIsLoading(false); // Set loading to false after fetching mobiles
        }
      } catch (error) {
        console.error(error);
        localStorage.setItem("country", "US");
        localStorage.setItem("currency", "$");
        dispatch(setCountryAndCurrency({ country: "US", currency: "$" }));

        if (!isDispatched.current) {
          await dispatch(fetchAllMobiles());
          isDispatched.current = true;
          setIsLoading(false); // Set loading to false after fetching mobiles
        }
      }
    };

    if (savedCountry && savedCurrency) {
      dispatch(
        setCountryAndCurrency({
          country: savedCountry,
          currency: savedCurrency,
        })
      );
      if (mobiles.length > 0) {
        if (
          mobiles[0].variants[0].priceInCurrency ===
            mobiles[0].variants[0].priceInUsd &&
          savedCurrency == "$"
        ) {
          // Do nothing
        } else if (
          mobiles[0].variants[0].priceInCurrency ===
            mobiles[0].variants[0].priceInUsd &&
          savedCurrency != "$"
        ) {
          const newMobiles = addpriceInCurrency(mobiles, savedCurrency);
          dispatch(setMobileData(newMobiles));
        }
      }

      if (!isDispatched.current) {
        dispatch(fetchAllMobiles()).then(() => {
          setIsLoading(false); // Set loading to false after fetching mobiles
        });
        dispatch(fetchAllUsedMobiles()).then(() => setIsLoading(false));

        isDispatched.current = true;
      } else {
        setIsLoading(false); // Set loading to false if already dispatched
      }
    } else {
      fetchIpInfo();
    }
  }, [dispatch, mobiles]);

  const currency = useSelector((state: RootState) => state.country.currency);

  useEffect(() => {
    if (isDispatched.current && status !== "loading") {
      setIsLoading(false);
    }
  }, [status]);

  return isLoading ? <ScreenLoader /> : null;
};

export default DataLoader;
