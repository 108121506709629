import { Link } from "react-router-dom";
import Edit_Icon from "../../../assets/edit_icon.png";

const Breadcrumb = () => {
  return (
    <div className="row mb-4 mt-5">
      <div className="col">
        <nav aria-label="breadcrumb ">
          <ol className="breadcrumb mb-0">
            <li
              className="breadcrumb-item"
              style={{ fontSize: "12px", fontWeight: "300" }}
            >
              <Link className="text-dark" to="/">
                Home
              </Link>
            </li>
            <li
              className="breadcrumb-item active"
              style={{
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              <p
                // to={`/${slugs.comparison}`}
                className="text-info"
                style={{
                  display: "inline",
                }}
              >
                My Ads
              </p>
              {/* Compare Apple iPhones 14 */}
            </li>
          </ol>
        </nav>
        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3
            style={{
              fontFamily: "Roboto",
              fontWeight: "800",
              fontSize: "24px",
            }}
          >
            Manage and View Your Ads
          </h3>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "8px",
              gap: "4px",
            }}
          >
            <img src={Edit_Icon} alt="" />
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                textDecoration: "underline",
                margin: "0px",
              }}
            >
              Edit Form Settings
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
