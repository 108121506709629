import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationCity from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

import { Link } from "react-router-dom";

const ContactSection = () => {
  return (
    <div className="contact-card">
      <h4>Contact Us</h4>
      <p>
        For inquiries or assistance, don't hesitate to reach out through our
        contact page. Your feedback fuels our commitment to serve you better.
      </p>
      <div className="contact-info">
        <div className="info-item">
          <div
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <LocationOnIcon />
          </div>
          <div>
            <h6>Address</h6>
            <p>Okara Punjab, Pakistan</p>
          </div>
        </div>
        <div className="info-item">
          <div
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <LocalPhoneIcon />
          </div>
          <div>
            <h6>Phone Number</h6>
            <p>+92 303 6969591</p>
          </div>
        </div>
        <div className="info-item">
          <div
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <EmailIcon />
          </div>
          <div>
            <h6>E-Mail</h6>
            <p>contact@ibadmobile.com</p>
          </div>
        </div>
      </div>
      <div className="social-media">
        <h6>Follow Us :</h6>
        <div className="social-icons d-flex ">
          <Link
            to="https://www.facebook.com/ibadmobile"
            target="_blank"
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <FacebookIcon />
          </Link>
          <Link
            to="https://www.instagram.com/ibad.mobile/"
            target="_blank"
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <InstagramIcon />
          </Link>
          <Link
            to="https://www.youtube.com/@ibadmobile-ku9vd"
            target="_blank"
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <YoutubeIcon />
          </Link>
          <Link
            to="mailto:contact@ibadmobile.com"
            target="_blank"
            className="bg-info  text-white  py-2 px-2 mx-1"
            style={{ borderRadius: "100%", fontSize: "10px" }}
          >
            <GoogleIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
