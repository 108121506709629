import React from 'react'
import LatestVideosCard from './LatestVideosCard'
import LatestVideosCard2 from './LatestVideosCard2'


const LatestVideos: React.FC = () => {
    return (
        <div className="container my-5">
            <div className="row">
                <h4 style={{fontWeight:'800',fontSize:'24px',fontFamily:'Roboto'}}>Our Latest Videos</h4>
                <p className='my-2 mb-4' style={{fontWeight:'300',fontFamily:'Roboto'}}>Experience the future of mobile purchasing through vivid visuals and expert insights</p>
            </div>
            <div className="row">
                <div className="col-12 col-lg-3 g-0">
                    <LatestVideosCard imgSrc="https://www.youtube.com/embed/9ziH0WqKE_A?si=hURQ7PQ0aV7cxTMX" />
                </div>
                <div className="col-12 col-lg-3 g-0">
                    <LatestVideosCard imgSrc="https://www.youtube.com/embed/dRQEqLrvIwQ?si=QHgoUrIqNmWAj6g4" />
                </div>
                <div className="col-12 col-lg-3 g-0">
                    <LatestVideosCard imgSrc="https://www.youtube.com/embed/9ziH0WqKE_A?si=hURQ7PQ0aV7cxTMX" />
                </div>
                <div className="col-12 col-lg-3 g-0">
                    <LatestVideosCard2 imgSrc="https://www.youtube.com/embed/9ziH0WqKE_A?si=hURQ7PQ0aV7cxTMX"/>
                    <LatestVideosCard2 imgSrc="https://www.youtube.com/embed/dRQEqLrvIwQ?si=QHgoUrIqNmWAj6g4"/>
                    <LatestVideosCard2 imgSrc="https://www.youtube.com/embed/9ziH0WqKE_A?si=hURQ7PQ0aV7cxTMX"/>
                </div>
            </div>
        </div>
    )
}

export default LatestVideos