import React from 'react'
import Footer from './Footer'

const index:React.FC = () => {
  return (
    <div><Footer/></div>
  )
}

export default index
