import "./mobile1.scss";
import { Link } from "react-router-dom";
import NewMobileCardGrid from "./NewMobileCardGrid";
import NewMobileCardList from "./NewMobileCardList";
import { MobileData } from "../NewMobiles/interface";

interface featuredData {
  featuredArray: MobileData[];
  expensiveArray: MobileData[];
  highRangeArray: MobileData[];
  midRangeArray: MobileData[];
  midLowRangeArray: MobileData[];
  lowRangeArray: MobileData[];
}
interface NewMobilesProps {
  viewMode: string;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  featuredData: featuredData;
  applyFilters: Function;
}

const ITEMS_PER_PAGE = 18;
const NewMobiles1: React.FC<NewMobilesProps> = ({
  viewMode,
  featuredData,
  setViewMode,
  applyFilters,
}) => {
  // Search handler

  return (
    <div className="container-fluid">
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Recommended Mobiles
          </h1>
          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => applyFilters("recommended", true)}
            className="btn btn-outline-info btn-sm me-4 "
          >
            View All
          </p>
        </div>
        {/* </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0"> */}
        {featuredData.featuredArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid"
                    ? "col-6 col-md-4 col-lg-3 p-1 p-md-2"
                    : "col-12"
                } mb-3`}
                style={{}}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2 ">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Mobile Prices in Pakistan &gt; Rs 50,000{" "}
          </h1>
          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => {
              applyFilters("minPrice", 50000);
            }}
            className="btn btn-outline-info btn-sm me-4 "
          >
            {/* <Link to="/new-mobile" className="btn btn-outline-info btn-sm me-4 "> */}
            View All
          </p>
        </div>
        {/* </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0"> */}
        {featuredData.expensiveArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid" ? "col-6 col-md-4 col-lg-3" : "col-12"
                }  mb-3`}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2 ">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Mobile Prices Rs 40,000 - Rs 50,000{" "}
          </h1>
          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => {
              applyFilters("maxAndMaxPrice", {
                min: 40000,
                max: 50000,
              });
            }}
            className="btn btn-outline-info btn-sm me-4 "
          >
            View All
          </p>
        </div>
        {featuredData.highRangeArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid" ? "col-6 col-md-4 col-lg-3" : "col-12"
                } mb-3`}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2 ">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Mobile Prices Rs 30,000 - Rs 40,000{" "}
          </h1>

          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => {
              applyFilters("maxAndMaxPrice", {
                min: 30000,
                max: 40000,
              });
            }}
            className="btn btn-outline-info btn-sm me-4 "
          >
            View All
          </p>
        </div>
        {featuredData.midRangeArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid" ? "col-6 col-md-4 col-lg-3" : "col-12"
                } mb-3`}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2 ">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Mobile Prices Rs 20,000 - Rs 30,000
          </h1>

          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => {
              applyFilters("maxAndMaxPrice", {
                min: 20000,
                max: 30000,
              });
            }}
            className="btn btn-outline-info btn-sm me-4 "
          >
            View All
          </p>
        </div>
        {featuredData.midLowRangeArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid" ? "col-6 col-md-4 col-lg-3" : "col-12"
                } mb-3`}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
      <div className="row mt-4 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2 ">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Mobile Prices Rs 10,000 - Rs 20,000{" "}
          </h1>

          <p
            style={{ minWidth: "80px", marginLeft: "10px" }}
            onClick={() => {
              applyFilters("maxAndMaxPrice", {
                min: 10000,
                max: 20000,
              });
            }}
            className="btn btn-outline-info btn-sm me-4 "
          >
            View All
          </p>
        </div>
        {featuredData.lowRangeArray
          .sort(
            (a, b) =>
              b.variants[0].priceInCurrency - a.variants[0].priceInCurrency
          )
          .map((mobiles, i) => {
            return (
              <div
                className={` ${
                  viewMode === "grid" ? "col-6 col-md-4 col-lg-3" : "col-12"
                } mb-3`}
                key={i}
              >
                <Link to={`/mobile-details/${mobiles.id}/${mobiles.modelName}`}>
                  {viewMode === "grid" ? (
                    <NewMobileCardGrid mobile={mobiles} />
                  ) : (
                    <NewMobileCardList mobile={mobiles} />
                  )}
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default NewMobiles1;
