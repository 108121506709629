import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { addpriceInCurrency } from '../../DataLoader';
import mobilesData from '../../data/mobiles.json';

// Async thunk for fetching all mobile data
export const fetchAllMobiles = createAsyncThunk(
    'mobiles/fetchAll',
    async (_, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState; // Cast getState() to RootState
            const currency = state.country.currency; // Access the country and currency values
         //   console.log("+++currency+++")
         //   console.log(currency)
            // Read data from imported JSON file
            let mobiles: any[] = mobilesData;
            mobiles = addpriceInCurrency(mobiles, currency);

            return mobiles;
        } catch (error) {
            console.error("Error fetching mobile data: ", error);
            return rejectWithValue(error);
        }
    }
);

const mobilesSlice = createSlice({
    name: 'mobiles',
    initialState: {
        mobiles: [] as any[],
        filteredMobiles: [] as any[],
        error: null as string | null,
        status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    },
    reducers: {
        setMobileData: (state, action) => {
            state.mobiles = action.payload;
        },
        setFilteredMobiles: (state, action) => {
            state.filteredMobiles = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMobiles.pending, (state) => {
                state.error = null;
                state.status = 'loading';
            })
            .addCase(fetchAllMobiles.fulfilled, (state, action) => {
             //   console.log('Fetched mobiles:', action.payload);
                state.mobiles = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchAllMobiles.rejected, (state, action) => {
                state.error = action.error.message ?? 'An unknown error occurred';
                state.status = 'failed';
            });
    }
});

export const { setMobileData, setFilteredMobiles } = mobilesSlice.actions;
export default mobilesSlice.reducer;
