import React from "react";
import ComparisonMobiles from "./ComparisonMobiles";
import { Helmet } from "react-helmet";

const index: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Mobile Phone Comparison - Ibad Mobile</title>
        <meta
          name="description"
          content="Compare features, specs, and prices on Ibad Mobile's Mobile Phone Comparison page to discover your ideal smartphone."
        />
        <meta
          name="keywords"
          content="mobile phone comparison, compare mobile phones, mobile comparison, best mobile deals, phone features, phone specifications, ibad mobile comparison"
        />
      </Helmet>
      <ComparisonMobiles />
    </>
  );
};

export default index;
